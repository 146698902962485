import React from "react";
import {Menu} from "antd";
import {Link, Redirect} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {useSelector} from "react-redux";

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

    const {authUser} = useSelector(state => state.auth);

    const {navStyle, themeType} = useSelector(({settings}) => settings);
    const pathname = useSelector(({common}) => common.pathname);

    const getNoHeaderClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
            return "gx-no-header-notifications";
        }
        return "";
    };

    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
        <>
            <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
            <div className="gx-sidebar-content">
                <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
                    <UserProfile/>
                    <AppsNavigation/>
                </div>
                <CustomScrollbars className="gx-layout-sider-scrollbar">
                    <Menu
                        defaultOpenKeys={[defaultOpenKeys]}
                        selectedKeys={[selectedKeys]}
                        theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                        mode="inline">

                        <Menu.Item key="sample">
                            <Link to="/sample"><i
                                className="icon icon-widgets"/>
                                <span><IntlMessages id="sidebar.samplePage"/></span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="chat">
                            <Link to="/chat"><i
                                className="icon icon-chat-bubble -flex-column-reverse"/><span><IntlMessages
                                id="sidebar.chatApp"/></span></Link>
                        </Menu.Item>

                        <Menu.Item key="account">
                            <Link to="/account"><i
                                className="icon icon-avatar -flex-column-reverse"/><span><IntlMessages
                                id="sidebar.accountApp"/></span></Link>
                        </Menu.Item>

                        <Menu.Item key="invoice">
                            <Link to="/invoice"><i
                                className="icon icon-shopping-cart -flex-column-reverse"/><span><IntlMessages
                                id="sidebar.invoiceApp"/></span></Link>
                        </Menu.Item>

                        <Menu.Item key="balance">
                            <Link to="/balance"><i
                                className="icon icon-orders -flex-column-reverse"/><span><IntlMessages
                                id="sidebar.balanceApp"/></span></Link>
                        </Menu.Item>

                        <Menu.Item key="customers">
                            <Link to="/customers"><i
                                className="icon icon-all-contacts -flex-column-reverse"/><span><IntlMessages
                                id="sidebar.customersApp"/></span></Link>
                        </Menu.Item>

                        <Menu.Item key="logging">
                            <Link to="/logging"><i
                                className="icon icon-keyboard -flex-column-reverse"/><span><IntlMessages
                                id="sidebar.loggingApp"/></span></Link>
                        </Menu.Item>

                        <Menu.Item key="packets">
                            <Link to="/packets"><i
                                className="icon icon-camera -flex-column-reverse"/><span><IntlMessages
                                id="sidebar.packetsApp"/></span></Link>
                        </Menu.Item>

                        {
                            authUser.is_superuser ?
                            <Menu.Item key="settings">
                                <Link to="/settings"><i
                                    className="icon icon-setting -flex-column-reverse"/><span><IntlMessages
                                    id="sidebar.settingApp"/></span></Link>
                            </Menu.Item> :
                                <Redirect
                                    to={{
                                        pathname: '/sample'
                                    }}
                                />
                        }

                    </Menu>
                </CustomScrollbars>
            </div>
        </>
    );
};

export default React.memo(SidebarContent);

