import React from "react";
import {Avatar, Popover} from "antd";
import {useAuth} from "../../authentication";
import {useHistory} from "react-router-dom";
import ModalUserProfile from "../../containers/Sidebar/ModalUserProfile";
import {useSelector} from "react-redux";

const UserInfo = () => {
    const {authUser} = useSelector(state => state.auth)
    const {userSignOut} = useAuth();
    const history = useHistory();

    const onLogoutClick = () => {
        userSignOut(() => {
            history.push('/');
        });
    }

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li>My Account</li>
            <ModalUserProfile/>
            <li onClick={onLogoutClick}>Logout
            </li>
        </ul>
    );

    return (
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
                 trigger="click">
            <Avatar className="gx-avatar gx-pointer" alt="">{authUser.first_name.charAt(0)}</Avatar>
        </Popover>
    );
};

export default UserInfo;
