import React, {useState} from 'react';
import {Form, Input, Modal, Select, Tooltip} from "antd";

const {Option} = Select;

const ModalAddBalance = ({text, fromModalAddBalance}) => {

    const {id, email} = text
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false)

    const layout = {
        labelCol: {
            span: 5,
        },
        wrapperCol: {
            span: 19,
        },
    };

    const prefixSelector = (
        <Form.Item
            name="action"
            noStyle
            initialValue={'true'}
        >
            <Select>
                <Option value={'true'}>Пополнение</Option>
                <Option value={'false'}>Снятие</Option>
            </Select>
        </Form.Item>
    );

    const onFinish = values => {
        Object.assign(values, {id: id});
        fromModalAddBalance(values);
        setVisible(false)
        form.resetFields()
    };

    const chowModal = () => {
        form.setFieldsValue({
            amount: '1.00'
        })
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    return (
        <>
            <Tooltip title={'Пополнение баланса'}>
                <i
                    className="icon icon-revenue-new"
                    onClick={chowModal}
                />
            </Tooltip>
            <Modal
                title={`Баланса для ${email}`}
                open={visible}
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    form={form}
                    name="balance"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="amount"
                        label="Действие"
                    >
                        <Input
                            addonBefore={prefixSelector}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
};

export default ModalAddBalance;