import React from "react";
import {Avatar, Popover} from "antd";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

import {useAuth} from "../../authentication";
import ModalUserProfile from "./ModalUserProfile";

const UserProfile = () => {
    const {authUser} = useSelector(state => state.auth)
    const {userSignOut} = useAuth();
    const history = useHistory();

    const onLogoutClick = () => {
        userSignOut(() => {
            history.push('/');
        });
    }

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li>My Account</li>
            <ModalUserProfile/>
            <li onClick={onLogoutClick}>
                Logout
            </li>
        </ul>
    );

    return (
        <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
            <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
                <Avatar className="gx-size-40 gx-pointer gx-mr-3" alt="">{authUser.first_name.charAt(0)}</Avatar>
                <span className="gx-avatar-name">{`${authUser.first_name}`}<i
                    className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
            </Popover>
        </div>
    )
};

export default UserProfile;
