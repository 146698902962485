import axios from 'axios';

export const httpClient = axios.create({
    baseURL: window.location.origin === 'http://localhost:3000' || window.location.origin === 'http://127.0.0.1:3000'
        ? 'http://localhost:8000/'
        : 'https://srv4you.net/api/v1/', //YOUR_API_URL HERE
    headers: {
        'Content-Type': 'application/json'
    },
});

httpClient.interceptors.request.use(
    function (config) {
        return config;
    }, function (error) {
        return Promise.reject(error);
    }
)
