import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, Input, InputNumber, Select} from "antd";

import {addChannel} from "../../appRedux/actions";

const { Option } = Select;

const AddChannel = () => {

  const dispatch = useDispatch()
  const [form] = Form.useForm();

  const {groups, countries} = useSelector(state => state.packet)

  const onFinish = values => {
    dispatch(addChannel(values))
    form.resetFields()
  };

  const onChange = (value) => {
  }

  return (
    <Form
      form={form}
      initialValues={{remember: true}}
      name="basic"
      onFinish={onFinish}
      className="gx-signin-form gx-form-row0"
      layout="inline"
    >
      <Form.Item
        name="name"
        rules={[{required: true, message: 'Please input name channel!'}]}
      >
        <Input placeholder="Name channel"/>
      </Form.Item>
      <Form.Item
        name="flussonic"
        rules={[{required: true, message: 'Please input Flussonic Id!'}]}
      >
        <Input placeholder="Id"/>
      </Form.Item>
      <Form.Item
        name="smart_epg"
      >
        <Input placeholder="Smart Epg"/>
      </Form.Item>
      <Form.Item
        name="parser_epg"
      >
        <Input placeholder="Parser Epg"/>
      </Form.Item>
      <Form.Item
          name="reference"
      >
        <Input placeholder="Reference"/>
      </Form.Item>
      <Form.Item
        name="archive"
        rules={[{required: true, message: 'Please input days for archive!'}]}
      >
        <InputNumber style={{ marginRight: 0, width: '100%' }} placeholder="Day" min={0} max={10} step={1} onChange={onChange}/>
      </Form.Item>
      <Form.Item
        name="group"
        rules={[
          {
            required: true,
            message: 'Please select your group',
          },
        ]}
      >
        <Select placeholder="Please select a group" style={{width: 193}}>
          {
            groups.map(x =>
              <Option key={x.id} value={x.group}>{x.name}</Option>
            )
          }
        </Select>
      </Form.Item>
      <Form.Item
        name="country"
        rules={[
          {
            required: true,
            message: 'Please select your country',
          },
        ]}
      >
        <Select placeholder="Please select a country" style={{width: 193}}>
          {
            countries.map(x =>
              <Option key={x.id} value={x.country}>{x.name}</Option>
            )
          }
        </Select>
      </Form.Item>
      <Form.Item>
        <Button style={{width: 193}}
                className="gx-mb-0"
                type="primary"
                htmlType="submit"
        >
          Создать
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AddChannel
