import {fetchError, fetchStart, fetchSuccess, showMessage} from "./Common";
import {httpClient} from "../../util/Api";
import {refreshToken} from "./Auth";
import {
    ADD_CHANNEL,
    LOAD_CHANNELS, DELETE_CHANNEL, EDIT_CHANNEL,
    LOAD_STATUS_SORTED_CHANNELS
} from "../../constants/ActionTypes";
import {accessForbidden, internalServerError} from "../../util/config";

export const getChannels = () => async (dispatch) => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.get(`channel/channel/`);
        dispatch({
            type: LOAD_CHANNELS,
            payload: data.list,
        });
        dispatch({
            type: LOAD_STATUS_SORTED_CHANNELS,
            payload: data.sorted,
        });
        dispatch(fetchSuccess());
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(getChannels)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
};

export const addChannel = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        const res = await httpClient.post(`channel/channel/`, values)
        dispatch({
            type: ADD_CHANNEL,
            payload: res.data
        })
        dispatch(fetchSuccess());
        dispatch(showMessage('Канал успешно добавлен'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(addChannel, values)
            : status === 400
                ? fetchError('Канал c таким Id уже существует')
                : status === 403
                    ? fetchError(accessForbidden)
                    : fetchError(internalServerError)
        dispatch(action)
    }
}


export const dropAndDragChannels = (value) => dispatch => {
    dispatch({
        type: LOAD_CHANNELS,
        payload: value
    })
}

export const editChannel = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.patch(`channel/channel/${values.id}/`, values)
        dispatch({
            type: EDIT_CHANNEL,
            payload: data
        })
        dispatch(fetchSuccess());
        dispatch(showMessage('Канал успешно изменен'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(editChannel, values)
            : status === 400
                ? fetchError('Канал c таким Id уже существует')
                : status === 403
                    ? fetchError(accessForbidden)
                    : fetchError(internalServerError)
        dispatch(action)
    }
}

export const delChannel = (value) => async dispatch => {
    dispatch(fetchStart());
    try {
        const res = await httpClient.delete(`channel/channel/${value}/`)
        dispatch({
            type: DELETE_CHANNEL,
            payload: res.data
        })
        dispatch(fetchSuccess());
        dispatch(showMessage('Канал успешно удален'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(delChannel, value)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}

export const saveSortedChannels = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.post(`channel/channel/sorted/`, values)
        dispatch(getChannels())
        dispatch(showMessage(data.msg))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(saveSortedChannels, values)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}

export const removeSortedChannels = () => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.post(`channel/channel/remove_sorted/`)
        dispatch(getChannels())
        dispatch(showMessage(data.msg))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(removeSortedChannels)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}