import React, {useState} from 'react';
import {Button, Card} from "antd";
import ModalCreatePaymentClient from "./ModalCreatePaymentClient";
import {useSelector} from "react-redux";
import ClientPaymentInfo from "./ClientPaymentInfo";

const PaymentInfo = ({dataCountry, dataClient, setDataClient}) => {

    const {authUser} = useSelector(state => state.auth)
    const [visibleModalCreatePaymentClient, setVisibleModalCreatePaymentClient] = useState(false)

    const chowModal = () => {
        setVisibleModalCreatePaymentClient(true)
    }

    return (
        <Card title="Платежная информация">
            {
                authUser.payment_id_client ?
                    <ClientPaymentInfo dataCountry={dataCountry} dataClient={dataClient} setDataClient={setDataClient}/> :
                    <Button
                        type="primary"
                        onClick={chowModal}
                    >
                        Активация платежного аккаунта
                    </Button>
            }
            <ModalCreatePaymentClient
                authUser={authUser}
                dataCountry={dataCountry}
                visibleModalCreatePaymentClient={visibleModalCreatePaymentClient}
                setVisibleModalCreatePaymentClient={setVisibleModalCreatePaymentClient}
            />
        </Card>
    );
};

export default PaymentInfo;