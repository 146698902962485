import React, {useState} from 'react';
import {Form, Modal, Radio} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {setPageSize, setThemeType} from "../../appRedux/actions";
import {THEME_TYPE_DARK, THEME_TYPE_LITE, THEME_TYPE_SEMI_DARK} from "../../constants/ThemeSetting";

const ModalUserProfile = () => {

    const [visible, setVisible] = useState(false)
    const {themeType, pageSize} = useSelector(({settings}) => settings);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const onThemeTypeChange = (e) => {
        dispatch(setThemeType(e.target.value));
    };

    const onPageSizeChange = (e) => {
        dispatch(setPageSize(e.target.value));
    }

    const chowModal = () => {
        setVisible(true)
        form.setFieldsValue({
            layout: themeType,
            pageSize: pageSize.toString()
        })
    }

    const handleCancel = () => {
        setVisible(false)
    }

    return (
        <>
            <li onClick={chowModal}>Setting</li>
            <Modal
                title={'Site Setting'}
                visible={visible}
                onOk={handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Form
                    {...layout}
                    form={form}
                    name="userProfileSetting"
                >
                    <Form.Item label="Page Size" name="pageSize">
                        <Radio.Group value={pageSize} onChange={onPageSizeChange}>
                            <Radio value='10'>10</Radio>
                            <Radio value='20'>20</Radio>
                            <Radio value='50'>50</Radio>
                            <Radio value='100'>100</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Theme" name="layout">
                        <Radio.Group value={themeType} onChange={onThemeTypeChange}>
                            <Radio.Button value={THEME_TYPE_LITE}>Lite</Radio.Button>
                            <Radio.Button value={THEME_TYPE_SEMI_DARK}>Semi Dark</Radio.Button>
                            <Radio.Button value={THEME_TYPE_DARK}>Dark</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>

        </>
    );
};

export default ModalUserProfile;