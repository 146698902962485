import {fetchError, fetchStart, fetchSuccess, showMessage} from "./Common";
import {httpClient} from "../../util/Api";
import {refreshToken} from "./Auth";
import {internalServerError} from "../../util/config";
import {LOAD_SETTING_MULTIROOM} from "../../constants/ActionTypes";


export const getSettingMultiRoom = () => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.get(`setting/multiroom/list/`)
        dispatch(fetchSuccess())
        dispatch({
            type: LOAD_SETTING_MULTIROOM,
            payload: data
        })
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(getSettingMultiRoom)
            : fetchError(internalServerError)
        dispatch(action)
    }
}

export const addSettingMultiRoom = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.post(`setting/multiroom/add/`, values)
        dispatch(getSettingMultiRoom())
        dispatch(showMessage('Мультирум успешно добавлен'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(addSettingMultiRoom, values)
            : fetchError(internalServerError)
        dispatch(action)
    }
}

export const editSettingMultiRoom = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.patch(`setting/multiroom/${values.id}/`, values)
        dispatch(getSettingMultiRoom())
        dispatch(showMessage('Мультирум успешно изменен'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(editSettingMultiRoom, values)
            : fetchError(internalServerError)
        dispatch(action)
    }
}
export const deleteSettingMultiRoom = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.delete(`setting/multiroom/${values.id}/`)
        dispatch(getSettingMultiRoom())
        dispatch(showMessage('Мультирум успешно удален'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(deleteSettingMultiRoom, values)
            : fetchError(internalServerError)
        dispatch(action)
    }
}