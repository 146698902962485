import {useEffect} from 'react';
import {httpClient} from "../../../util/Api";
import {useDispatch} from "react-redux";
import {
    fetchError,
    fetchStart,
    fetchSuccess,
    getAuthUserContext,
    getAuthUserReducer,
    isLoadingUser,
    setInitUrl,
    showMessage,
    signOutContext
} from "../../../appRedux/actions";

export const useProvideAuth = () => {
    const dispatch = useDispatch()

    const refreshToken = async (callbackFun, values) => {
        try {
            const refresh = localStorage.getItem('refresh');
            if (!refresh) {
                dispatch(isLoadingUser(false));
                return;
            }
            const {data} = await httpClient.post('auth/jwt/refresh/', {refresh});
            if (data) {
                dispatch(fetchSuccess());
                localStorage.setItem('access', data.access);
                localStorage.setItem('refresh', data.refresh);
                httpClient.defaults.headers.common['Authorization'] = 'JWT ' + data.access;
                if (callbackFun) {
                    const result = await callbackFun(values)
                    dispatch(isLoadingUser(false));
                    return result
                }
                dispatch(isLoadingUser(false));
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(signOutContext('Сессия истекла'));
            }
        }
    };

    const userLogin = async (user) => {
        dispatch(fetchStart());
        try {
            const {data} = await httpClient.post('user/jwt_create', user)
            if (data) {
                httpClient.defaults.headers.common['Authorization'] = 'JWT ' + data.access;
                localStorage.setItem('access', data.access);
                localStorage.setItem('refresh', data.refresh);
                dispatch(getAuthUserReducer())
                dispatch(showMessage('Вы успешно вошли в систему'))
            }
        } catch (error) {
            const keys = Object.keys(error.response.data)
            if (Array.isArray(error.response.data[keys[0]])) {
                error.response.data[keys[0]].forEach(element => {
                    dispatch(fetchError(element))
                })
            } else {
                dispatch(fetchError(error.response.data[keys[0]]))
            }
        }
    };

    const userSignup = (user) => {
        dispatch(fetchStart());
        httpClient
            .post('auth/users/', user)
            .then((res) => {
                if (res) {
                    dispatch(showMessage('Для активации учетной записи обратитесь в Telegram вашего администратора'))
                } else {

                }
            })
            .catch(error => {
                const keys = Object.keys(error.response.data)
                if (Array.isArray(error.response.data[keys[0]])) {
                    error.response.data[keys[0]].forEach(element => {
                        dispatch(fetchError(element))
                    })
                }
            });
    };

    const sendPasswordResetEmail = (email) => {
        dispatch(fetchStart());
        httpClient
            .post('auth/users/reset_password/', email)
            .then(() => {
                dispatch(showMessage('Сылка на востановление пароля отправленна на указаный Email'))
                dispatch(setInitUrl('/'))
            })
            .catch()
    };

    const confirmPasswordReset = (uid, token, values) => {
        dispatch(fetchStart());
        const body = {
            uid: uid,
            token: token,
            new_password: values.password,
            re_new_password: values.confirm
        };
        httpClient
            .post('auth/users/reset_password_confirm/', body)
            .then((res) => {
                dispatch(fetchSuccess());
                if (res.status === 204) {
                    dispatch(showMessage('Пароль успешно изменен'))
                    dispatch(setInitUrl('/signin'))
                }
            })
            .catch()
    };

    const confirmEmailSignUp = (uid, token) => {
        dispatch(fetchStart());
        const body = JSON.stringify({uid, token});
        httpClient
            .post('auth/users/activation/', body)
            .then(res => {
                    dispatch(showMessage('Ваша учетная запись была успешно создана и активирована!'))
                    dispatch(setInitUrl('/signin'))
                }
            )
            .catch(() => {
                dispatch(setInitUrl('/signin'))
            })
    };

    const renderSocialMediaLogin = () => null;

    const userSignOut = async () => {
        dispatch(fetchStart());
        try {
            const {status} = await httpClient.post('user/logout_all/')
            if (status === 205) dispatch(signOutContext('Вы успешно вышли из системы'))
        } catch (error) {
            if (error.response.status === 401) {
                await refreshToken(userSignOut)
            }
        }
    };

    const hooks = async () => {
        const token = localStorage.getItem('access');
        if (token) {
            httpClient.defaults.headers.common['Authorization'] = 'JWT ' + token;
            try {
                const {data} = await httpClient.get('auth/users/me/');
                if (data) await dispatch(getAuthUserContext(data));
                dispatch(isLoadingUser(false));
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    await refreshToken(hooks);
                } else {
                    dispatch(signOutContext('Сессия истекла'));
                }
            }
        } else {
            dispatch(isLoadingUser(false));
        }
    }

    useEffect(() => {
        hooks().then();
    }, []);

    return {
        refreshToken,
        userLogin,
        userSignup,
        userSignOut,
        renderSocialMediaLogin,
        sendPasswordResetEmail,
        confirmPasswordReset,
        confirmEmailSignUp
    };
};
