import React from 'react';
import {Card, Form, Space, Typography} from "antd";
import {useSelector} from "react-redux";
import ModalRepayment from "./ModalRepayment";

const FormPersonalData = () => {

    const {authUser} = useSelector(state => state.auth)

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };

    return (
        <Card className="gx-card" title="Форма личных данных">
            <Form
                {...formItemLayout}
            >
                <Form.Item
                    label={'Email'}
                >
                    <span className='gx-d-block'>{authUser.email}</span>
                </Form.Item>
                <Form.Item
                    label={'Balance'}
                >
                    <span className='gx-d-block'>{authUser.balance}</span>
                </Form.Item>
                {
                    authUser.is_staff && !authUser.is_superuser &&
                    <Form.Item
                        label={'Credit'}
                    >
                        <Space>
                            <Typography.Text style={{color: '#e0e0e0'}}>
                                {authUser.credit}
                            </Typography.Text>
                            <ModalRepayment amount={authUser.credit}/>
                        </Space>
                    </Form.Item>
                }
            </Form>
        </Card>
    );
};

export default FormPersonalData;