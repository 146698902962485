import enLang from "./entries/en-US";
import deLang from "./entries/de_DE";
import itLang from "./entries/it_IT";
import esLang from "./entries/es_ES";
import frLang from "./entries/fr_FR";
import ruLang from "./entries/ru_RU";

const AppLocale = {
  ru: ruLang,
  en: enLang,
  de: deLang,
  it: itLang,
  es: esLang,
  fr: frLang
};

export default AppLocale;
