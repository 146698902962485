import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal, Space, Table, Tooltip} from "antd";
import {
    CheckCircleTwoTone,
    DollarOutlined,
    ExclamationCircleOutlined,
    HeartTwoTone,
    MenuOutlined
} from '@ant-design/icons';
import {DndContext} from "@dnd-kit/core";
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {
    delChannel,
    dropAndDragChannels,
    editChannel,
    getChannels, removeSortedChannels,
    saveSortedChannels, showMessage
} from "../../appRedux/actions";
import ModalEditChannel from "./ModalEditChannel";
import {SearchTable} from "../../util/SearchTable";
import {copyToClip} from "../../util/copyToClip";

const {Column} = Table;
const {confirm} = Modal;

const Row = ({children, ...props}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    });
    const style = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1,
            },
        ),
        transition,
        ...(isDragging
            ? {
                position: 'relative',
                zIndex: 9999,
            }
            : {}),
    };
    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if (child.key === 'sort') {
                    return React.cloneElement(child, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{
                                    touchAction: 'none',
                                    cursor: 'move',
                                }}
                                {...listeners}
                            />
                        ),
                    });
                }
                return child;
            })}
        </tr>
    );
};

const ListChannels = () => {

    const {authUser} = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const {getColumnSearchProps} = SearchTable()
    const {channels, sortedChannels, groups, countries} = useSelector(state => state.packet);
    const [statusSave, setStatusSave] = useState(true);

    useEffect(() => {
        dispatch(getChannels())
    }, []);

    const showConfirmActive = () => {
        confirm({
            title: 'Вы хотите очистить сортировку каналов?',
            icon: <ExclamationCircleOutlined/>,
            content: 'После очистки сортировки вам будет доступна сортировка по умолчанию',
            onOk() {
                dispatch(removeSortedChannels())
            },
            onCancel() {
            },
        });
    };

    const onDragEnd = ({active, over}) => {
        if (active.id !== over?.id) {
            const overIndex = channels.findIndex((i) => i.sorted_index === over?.id);
            const activeIndex = channels.findIndex((i) => i.sorted_index === active.id);
            dispatch(dropAndDragChannels(arrayMove(channels, activeIndex, overIndex)));
            if (statusSave === true) {
                setStatusSave(!statusSave)
            }
        }
    };

    const copyToClipPng = (url) => {
        navigator.clipboard.writeText(`${url.slice(0, -1).replaceAll(':', '_')}.png`)
        dispatch(showMessage('Copy this text to clipboard'))
    }

    const channelsFilterActive = channels.filter((item => authUser.is_superuser ? item : item.active === true))
    return (
        <>
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext
                    items={channels.map((i) => i.sorted_index)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        rowKey='sorted_index'
                        className="gx-table-responsive"
                        dataSource={channelsFilterActive}
                        pagination={false}
                        components={{
                            body: {
                                row: Row,
                            },
                        }}
                        size="small"
                    >
                        <Column title="Sort"
                                dataIndex="sort"
                                align={'center'}
                                key="sort"
                                width={30}
                        />
                        {
                            authUser.is_superuser &&
                            <Column title=""
                                    dataIndex="active"
                                    align={'center'}
                                    key="active"
                                    width={20}
                                    render={value => (
                                        <>
                                            {
                                                value &&
                                                <CheckCircleTwoTone twoToneColor="#52c41a"/>
                                            }
                                        </>
                                    )}
                            />
                        }
                        <Column title=""
                                dataIndex="paid"
                                align={'center'}
                                key="paid"
                                width={20}
                                render={value => (
                                    <>
                                        {
                                            value &&
                                            <DollarOutlined twoToneColor="#52c41a"/>
                                        }
                                    </>
                                )}
                        />
                        <Column title=""
                                dataIndex="parental_control"
                                align={'center'}
                                key="paid"
                                width={20}
                                render={value => (
                                    <>
                                        {
                                            value &&
                                            <HeartTwoTone twoToneColor="#eb2f96"/>
                                        }
                                    </>
                                )}
                        />
                        <Column title="Название канала"
                                dataIndex="name"
                                key="name"
                                {...getColumnSearchProps('name')}
                                onFilter={(value, record) =>
                                    record['name']
                                        ? record['name'].toString().toLowerCase().includes(value.toLowerCase())
                                        : ''
                                }
                                onCell={(record) => ({
                                    onClick: () => copyToClip(record.name, dispatch),
                                })}
                        />
                        {
                            authUser.is_staff &&
                            <>
                                <Column title="Id"
                                        dataIndex="flussonic"
                                        key="flussonic"
                                        sorter={(a, b) => a.flussonic.localeCompare(b.flussonic)}
                                        onCell={(record) => ({
                                            onClick: () => copyToClip(record.flussonic, dispatch),
                                        })}
                                />
                                <Column title="Smart EPG"
                                        dataIndex="smart_epg"
                                        key="smart_epg"
                                        onCell={(record) => ({
                                            onClick: () => copyToClip(record.smart_epg, dispatch),
                                        })}
                                />
                                <Column title="Parser EPG"
                                        dataIndex="parser_epg"
                                        key="parser_epg"
                                        sorter={(a, b) => a.parser_epg.localeCompare(b.parser_epg)}
                                        onCell={(record) => ({
                                            onClick: () => copyToClip(record.parser_epg, dispatch),
                                        })}
                                />
                                <Column title="Reference Enigma"
                                        dataIndex="reference"
                                        key="reference"
                                        onCell={(record) => ({
                                            onClick: () => copyToClip(record.reference, dispatch),
                                        })}
                                />
                            </>
                        }
                        <Column title="Group"
                                dataIndex="group"
                                key="group"
                                filterSearch={true}
                                filters={groups}
                                onFilter={(value, record) => record.group.name.indexOf(value) === 0}
                                render={(text, record) => (
                                    <span>
                                {record.group.name}
                            </span>
                                )}
                        />
                        <Column title="Country"
                                dataIndex="country"
                                key="country"
                                filterSearch={true}
                                filters={countries}
                                onFilter={(value, record) => record.country.name.indexOf(value) === 0}
                                render={(text, record) => (
                                    <span>
                                {record.country.name}
                            </span>
                                )}
                        />
                        {
                            authUser.is_superuser &&
                            <Column title="Actions"
                                    width={100}
                                    align={'center'}
                                    dataIndex=""
                                    key="x"
                                    render={(text, record) => (
                                        <Space size="small">
                                            <Tooltip title={'Состояние канала'}>
                                                <i
                                                    className="icon icon-check"
                                                    onClick={() => dispatch(editChannel({
                                                        active: !record.active,
                                                        id: record.channel
                                                    }))}
                                                />
                                            </Tooltip>
                                            <ModalEditChannel text={text}/>
                                            <Tooltip title="Скопировать PNG">
                                                <i
                                                    className="icon icon-image"
                                                    onClick={() => copyToClipPng(record.reference)}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Удалить канал">
                                                <i
                                                    className="icon icon-trash"
                                                    onClick={() => dispatch(delChannel(record.channel))}
                                                />
                                            </Tooltip>
                                        </Space>
                                    )}
                            />
                        }
                    </Table>
                </SortableContext>
            </DndContext>
            <Button disabled={statusSave}
                    style={{marginTop: 20}}
                    onClick={() => {
                        setStatusSave(!statusSave)
                        dispatch(saveSortedChannels(channels))
                    }}>
                Save
            </Button>
            <Button disabled={!sortedChannels}
                    style={{marginTop: 20}}
                    onClick={() => {
                        showConfirmActive()
                    }}>
                Очистить сортировку
            </Button>
        </>
    )
};

export default ListChannels;
