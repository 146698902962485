import {Space, Table, Tooltip} from "antd";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {delPacket} from "../../appRedux/actions/Packet";
import ModalEditPacket from "./ModalEditPacket";
import ModalDetailPacket from "./ModalDetailPacket";
import ModalEditPacketChannel from "./ModalEditPacketChannel";

const {Column} = Table;

const ListPackets = () => {

    const {authUser} = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const {packets} = useSelector(state => state.packet);
    const {manageAccountForFilter} = useSelector(state => state.customer);
    const {pageSize} = useSelector(({settings}) => settings);
    const defaultFalse = packets.filter(item => !item.is_default);
    const defaultTrue = packets.filter(item => item.is_default);

    return (
        <>
            {
                defaultFalse.length > 0 &&
                <Table
                    rowKey="id"
                    className="gx-table-responsive"
                    dataSource={defaultFalse}
                    size="small"
                    pagination={{
                        showSizeChanger: true,
                        defaultPageSize: pageSize,
                    }}
                >
                    <Column title="Название Пакета" dataIndex="name" key="name"/>
                    <Column title="Цена" dataIndex="price" key="price"/>
                    {
                        authUser.is_staff &&
                        <Column title={'Пользователь'}
                                dataIndex={'account'}
                                key={'account'}
                                render={value => value.email}
                                filterSearch={true}
                                filters={manageAccountForFilter.filter(item => item.is_active && authUser.is_superuser ? item.manager === 0 : true)}
                                onFilter={(value, record) => record.account.email.indexOf(value) === 0}
                        />
                    }
                    <Column
                        title="Action"
                        align={'center'}
                        width={70}
                        key="action"
                        render={(text, record) => (
                            <Space size="small">
                                {
                                    authUser.is_staff &&
                                    <ModalEditPacketChannel text={text}/>
                                }
                                <ModalDetailPacket text={text}/>
                                {
                                    authUser.is_staff &&
                                    <>
                                        <ModalEditPacket text={text}/>
                                        <Tooltip title="Удалить пакет">
                                            <i
                                                className="icon icon-trash"
                                                onClick={() => dispatch(delPacket(text.id))}
                                            />
                                        </Tooltip>
                                    </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            }
            {
                defaultTrue.length > 0 &&
                <Table
                    rowKey="id"
                    className="gx-table-responsive"
                    dataSource={defaultTrue}
                    size="small"
                >
                    <Column title="Название Пакета" dataIndex="name" key="name"/>
                    <Column title="Цена" dataIndex="price" key="price"/>
                    <Column
                        title="Action"
                        width={70}
                        key="action"
                        align={'center'}
                        render={(text, record) => (
                            <Space size="small">
                                {
                                    authUser.is_superuser &&
                                    <ModalEditPacketChannel text={text}/>
                                }
                                <ModalDetailPacket text={text}/>
                                {
                                    authUser.is_superuser &&
                                    <>
                                        <ModalEditPacket text={text}/>
                                        <Tooltip title="Удалить пакет">
                                            <i
                                                className="icon icon-trash"
                                                onClick={() => dispatch(delPacket(text.id))}
                                            />
                                        </Tooltip>
                                    </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            }
        </>
    )
};

export default ListPackets;
