import {
    DELETE_PACKET,
    ADD_CHANNEL,
    LOAD_CHANNELS,
    LOAD_CHANNELS_FOR_PACKET, LOAD_CHANNELS_PACKET_FOR_EDIT,
    LOAD_COUNTRY, DELETE_CHANNEL, EDIT_CHANNEL,
    LOAD_GROUPS, LOAD_PACKET,
    LOAD_PACKETS, LOAD_STATUS_SORTED_CHANNELS, LOAD_STATUS_SORTED_COUNTRY, LOAD_STATUS_SORTED_GROUPS, SIGNOUT_USER
} from "../../constants/ActionTypes";

const initialState = {
    packets: [],
    groups: [],
    sortedGroups: false,
    countries: [],
    sortedCountry: false,
    channels: [],
    sortedChannels: false,
    editChannelForPacket: []
}

const Packet = (state = initialState, action) => {
    const {type, payload} = action

    switch (type) {
        case LOAD_PACKET:
            const updatePackets = [...state.packets]
            const indexForEditPacket = updatePackets.findIndex(packet => packet.id === payload.id)
            updatePackets[indexForEditPacket] = payload
            return {
                ...state,
                packets: updatePackets,
            }
        case DELETE_PACKET:
            const newArrPackets = state.packets.filter(packet => packet.id !== payload.id)
            return {
                ...state,
                packets: newArrPackets
            }
        case LOAD_PACKETS:
            return {
                ...state,
                packets: payload
            }
        case LOAD_GROUPS:
            return {
                ...state,
                groups: payload
            }
        case LOAD_STATUS_SORTED_GROUPS:
            return {
                ...state,
                sortedGroups: payload
            }
        case LOAD_COUNTRY:
            return {
                ...state,
                countries: payload
            }
        case LOAD_STATUS_SORTED_COUNTRY:
            return {
                ...state,
                sortedCountry: payload
            }
        case LOAD_CHANNELS:
            return {
                ...state,
                channels: payload
            }
        case ADD_CHANNEL:
            return {
                ...state,
                channels: [...state.channels, payload]
            }
        case  EDIT_CHANNEL:
            const indexForEdit = state.channels.findIndex(channel => channel.id === payload.id);
            const updatedChannelsEdit = [...state.channels];
            updatedChannelsEdit[indexForEdit] = payload;
            return {
                ...state,
                channels: updatedChannelsEdit
            }
        case DELETE_CHANNEL:
            const updatedChannelsDelete = state.channels.filter(item => item.channel !== payload.id)
            return {
                ...state,
                channels: updatedChannelsDelete
            }
        case LOAD_STATUS_SORTED_CHANNELS:
            return {
                ...state,
                sortedChannels: payload
            }
        case LOAD_CHANNELS_FOR_PACKET:
            const packets = state.packets.map(item =>
                item.id === payload.id ? {...item, channels: payload.data.length !== 0 ? [payload.data] : []} : item
            )
            return {
                ...state,
                packets
            }
        case LOAD_CHANNELS_PACKET_FOR_EDIT:
            return {
                ...state,
                editChannelForPacket: payload
            }
        case SIGNOUT_USER:
            return initialState
        default:
            return state
    }
}

export default Packet;
