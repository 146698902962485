import React from 'react';
import {Card, Table, Tag, Tooltip} from "antd";
import {useInvoice} from "../../services/useInvoice";
import {copyToClip} from "../../util/copyToClip";
import {useDispatch} from "react-redux";
import ModalCreatePaymentInvoice from "./ModalCreatePaymentInvoice";

const ListInvoices = ({dataInvoices, setDataInvoices}) => {

    const {deleteInvoice} = useInvoice()
    const dispatch = useDispatch()

    const deleteInvoiceFromData = (id) => {
        const fetchDataDeleteInvoice = async () => {
            const itemsDelete = await deleteInvoice(id)
            if (itemsDelete && Object.keys(itemsDelete).length !== 0) {
                const modifyData = dataInvoices.filter(item => item.id_payment !== id)
                setDataInvoices(modifyData);
            }
        }
        fetchDataDeleteInvoice()
    }

    const column = [
        {
            title: 'ID',
            dataIndex: 'id_payment',
            key: 'id_payment',
        },
        {
            title: 'Период',
            dataIndex: 'period',
            key: 'period',
        },
        {
            title: 'Стоимость',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Создан',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Оплачен',
            dataIndex: 'paid_at',
            key: 'paid_at',
        },
        {
            title: 'Action',
            dataIndex: '',
            align: 'center',
            colSpan: 2,
            key: 'paid_at',
            width: '1%',
            render: (_, record) => (
                !record.paid_at &&
                <Tag
                    color='red'
                    style={{ margin: 0 }}
                    onClick={() => deleteInvoiceFromData(record.id_payment)}
                >
                    <a>Delete</a>
                </Tag>
            )
        },
        {
            colSpan: 0,
            dataIndex: '',
            width: '1%',
            render: (_, record) => (
                !record.paid_at &&
                <Tag
                    color='green'
                    style={{ margin: 0 }}
                    onClick={() => copyToClip(`https://payments-today.com/pay/${record.uuid_payment}`, dispatch)}
                >
                    <a>Paid</a>
                </Tag>
            )
        }
    ]
    return (
        <Card
            title="Список Счетов"
            extra={<ModalCreatePaymentInvoice dataInvoices={dataInvoices} setDataInvoices={setDataInvoices}/>}
        >
            <Table
                rowKey="id_payment"
                dataSource={dataInvoices}
                className="gx-table-responsive"
                columns={column}
                size={'small'}
            />
        </Card>
    );
};

export default ListInvoices;