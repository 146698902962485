import React, {useState} from 'react';
import {Form, Input, Modal, Select} from "antd";
import {useSelector} from "react-redux";
import {useInvoice} from "../../services/useInvoice";

const Option = Select.Option;

const ModalCreatePaymentInvoice = ({dataInvoices, setDataInvoices}) => {

    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm();
    const {createInvoice} = useInvoice()
    const {authUser} = useSelector(state => state.auth)

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const selectPeriod = [
        {'id': 1, 'period': '1 month'},
        {'id': 2, 'period': '2 months'},
        {'id': 3, 'period': '3 months'},
        {'id': 4, 'period': '4 months'},
        {'id': 5, 'period': '5 months'},
        {'id': 6, 'period': '6 months'},
        {'id': 7, 'period': '7 months'},
        {'id': 8, 'period': '8 months'},
        {'id': 9, 'period': '9 months'},
        {'id': 10, 'period': '10 months'},
        {'id': 11, 'period': '11 months'},
        {'id': 12, 'period': '1 Year'},
    ]

    const onFinish = async values => {
        const dataCreateInvoice = await createInvoice({...values, 'id_client': authUser.payment_id_client})
        dataCreateInvoice && setDataInvoices([dataCreateInvoice, ...dataInvoices])
        setVisible(false)
    };

    const chowModal = () => {
        form.setFieldsValue({
            price: '10.00',
            period: 1
        })
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    return (
        <>
            <span
                className="gx-link"
                onClick={chowModal}
            >
                + Создать счет
            </span>
            <Modal
                title={`Создание счета`}
                open={visible}
                okText="Создать"
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    form={form}
                    name="paymentInvoiceCreate"
                    onFinish={onFinish}
                    className="gx-signin-form gx-form-row0"
                >
                    <Form.Item
                        label={'Plan'}
                        name="period"
                        rules={[
                            {
                                required: true,
                                message: 'Please select your current country!',
                            },
                        ]}
                    >
                        <Select>
                            {selectPeriod.map(item => <Option
                                key={item.id}
                                value={item.id}>{item.period}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={'Price'}
                        name="price"
                        rules={[
                            {
                                required: true,
                                message: 'Please select your current country!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>

            </Modal>

        </>
    );
};

export default ModalCreatePaymentInvoice;