import {useDispatch} from "react-redux";
import {useAuth} from "../authentication";
import {fetchError, fetchStart, fetchSuccess, getAuthUserReducer} from "../appRedux/actions";
import {httpClient} from "../util/Api";
import {accessForbidden, internalServerError} from "../util/config";

export const useInvoice = () => {

    const dispatch = useDispatch()
    const {refreshToken} = useAuth()

    const getCountry = async () => {
        dispatch(fetchStart());
        try {
            const {data} = await httpClient.get(`invoices/country_list/`)
            dispatch(fetchSuccess())
            return data
        } catch (error) {
            if (error.response.status === 401) {
                return await refreshToken(getCountry)
            } else if (error.response.status === 403) {
                dispatch(fetchError(accessForbidden))
            } else {
                dispatch(fetchError(internalServerError))
            }
        }
    }

    const createClient = async (values) => {
        dispatch(fetchStart());
        try {
            const {status} = await httpClient.post(`invoices/create_client/`, values)
            if (status === 201) {
                const updateAuthUserReducer= async () => {
                    await dispatch(getAuthUserReducer())
                }
                updateAuthUserReducer()
            }
            dispatch(fetchSuccess())
        } catch (error) {
            if (error.response.status === 401) {
                refreshToken(createClient, values)
            } else if (error.response.status === 403) {
                dispatch(fetchError(accessForbidden))
            } else {
                dispatch(fetchError(internalServerError))
            }
        }
    }

    const getClient = async () => {
        dispatch(fetchStart());
        try {
            const {data} = await httpClient.get(`invoices/get_client/`)
            dispatch(fetchSuccess())
            return data
        } catch (error) {
            if (error.response.status === 401) {
                return await refreshToken(getClient)
            } else if (error.response.status === 403) {
                dispatch(fetchError(accessForbidden))
            } else {
                dispatch(fetchError(internalServerError))
            }
        }
    }

    const updateClient = async (values) => {
        dispatch(fetchStart());
        try {
            const {data} = await httpClient.post(`invoices/update_client/`, values)
            dispatch(fetchSuccess())
            return data
        } catch (error) {
            if (error.response.status === 401) {
                refreshToken(updateClient, values)
            } else if (error.response.status === 403) {
                dispatch(fetchError(accessForbidden))
            } else {
                dispatch(fetchError(internalServerError))
            }
        }
    }

    const createInvoice = async (values) => {
        dispatch(fetchStart());
        try {
            const {data} = await httpClient.post(`invoices/create_payment/`, values)
            dispatch(fetchSuccess())
            return data
        } catch (error) {
            if (error.response.status === 401) {
                return await refreshToken(createInvoice, values)
            } else if (error.response.status === 403) {
                dispatch(fetchError(accessForbidden))
            } else {
                dispatch(fetchError(internalServerError))
            }
        }
    }

    const getInvoices = async () => {
        dispatch(fetchStart());
        try {
            const {data} = await httpClient.get(`invoices/get_payments/`)
            dispatch(fetchSuccess())
            return data
        } catch (error) {
            if (error.response.status === 401) {
                return await refreshToken(getInvoices)
            } else if (error.response.status === 403) {
                dispatch(fetchError(accessForbidden))
            } else {
                dispatch(fetchError(internalServerError))
            }
        }
    }

    const deleteInvoice = async (value) => {
        dispatch(fetchStart());
        try {
            const {data} = await httpClient.post(`invoices/delete_payment/`, value)
            dispatch(fetchSuccess())
            return data
        } catch (error) {
            if (error.response.status === 401) {
                return refreshToken(deleteInvoice, value)
            } else if (error.response.status === 403) {
                dispatch(fetchError(accessForbidden))
            } else {
                dispatch(fetchError(internalServerError))
            }
        }
    }


    return {
        getCountry,
        getClient,
        createClient,
        updateClient,
        createInvoice,
        getInvoices,
        deleteInvoice
    }
}