import React, {useEffect, useState} from 'react';
import {Button, Modal, Table, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {CheckCircleTwoTone} from "@ant-design/icons";

import {getChannelsForEditPacketChannel, initChannelsForEditPacketChannel} from "../../appRedux/actions/Packet";
import {updateChannelsToPacket} from "../../appRedux/actions/PacketGroup";

const ModalEditPacketChannel = (values) => {

    const {name, id} = values.text
    const dispatch = useDispatch();
    const {editChannelForPacket, groups, countries} = useSelector(state => state.packet);
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [visible, setVisible] = useState(false)

    const findEditChannelForPacket = editChannelForPacket.filter(item => item.packets_id === id)

    const columns = [
        {
            title: '',
            dataIndex: 'active',
            width: '15px',
            render: (value) => (
                <>
                    {
                        value &&
                        <CheckCircleTwoTone twoToneColor="#52c41a"/>
                    }
                </>
            )
        },
        {
            title: 'Название канала',
            dataIndex: 'name',
            width: '400px'
        },
        {
            title: 'Группа',
            dataIndex: 'group',
            filterSearch: true,
            filters: groups,
            onFilter: (value, record) => record.group.indexOf(value) === 0
        },
        {
            title: 'Страна',
            dataIndex: 'country',
            filterSearch: true,
            filters: countries,
            onFilter: (value, record) => record.country.indexOf(value) === 0
        },
        {
            title: 'Action',
            align: 'center',
            width: '70',
            render: value => (
                <Tooltip title={'Состояние канала'}>
                    <i
                        className="icon icon-check"
                        onClick={() => dispatch(updateChannelsToPacket({
                            action: value.active === true ? 'del' : 'add',
                            id,
                            selectedRowKeys: [value.channels_id]
                        }))}
                    />
                </Tooltip>
            )
        }
    ];

    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE
        ],
    };

    useEffect(() => {
        if (findEditChannelForPacket.length > 0) {
            setSelectedRowKeys([])
            setVisible(true)
        }
    }, [editChannelForPacket])

    const addChannels = () => {
        dispatch(updateChannelsToPacket({action: 'add', id, selectedRowKeys}))
    }

    const delChannels = () => {
        dispatch(updateChannelsToPacket({action: 'del', id, selectedRowKeys}))
    }

    const chowModal = () => {
        dispatch(getChannelsForEditPacketChannel(id))
    }

    const handleCancel = () => {
        dispatch(initChannelsForEditPacketChannel)
        setSelectedRowKeys([])
        setVisible(false)
    }

    return (
        <>
            <Tooltip
                title="Редактирование каналов">
                <i
                    className="icon icon-apps"
                    onClick={chowModal}
                />
            </Tooltip>
            <Modal
                title={`Редактирование каналов в пакете ${name}`}
                visible={visible}
                onCancel={handleCancel}
                width={1000}
                footer={[
                    <Button key="cancel" disabled={selectedRowKeys.length !== 0 ? false : true} onClick={addChannels}>
                        Добавить
                    </Button>,
                    <Button key="submit" disabled={selectedRowKeys.length !== 0 ? false : true} onClick={delChannels}>
                        Отменить
                    </Button>,
                    <Button key="close" onClick={handleCancel}>
                        Закрыть
                    </Button>,
                ]}
            >
                <Table
                    rowSelection={rowSelection}
                    rowKey={'channels_id'}
                    columns={columns}
                    dataSource={findEditChannelForPacket}
                    size={"small"}
                    scroll={{ x: true }}
                />
            </Modal>
        </>
    );
};

export default ModalEditPacketChannel;