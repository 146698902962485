import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Common from "./Common";
import Auth from "./Auth";
import Customer from "./Customer";
import Packet from "./Packet";
import Balance from "./Balance";
import SettingsServer from "./SettingsServer";
import SettingMultiRoom from "./SettingsMultiRoom";

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: Auth,
  settings: Settings,
  common: Common,
  customer: Customer,
  packet: Packet,
  historyBalance: Balance,
  settingServer: SettingsServer,
  settingMultiRoom: SettingMultiRoom
});

export default createRootReducer;
