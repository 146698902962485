import {fetchError, fetchStart, fetchSuccess, showMessage} from "./Common";
import {httpClient} from "../../util/Api";
import {
    DELETE_PACKET,
    LOAD_CHANNELS_FOR_PACKET,
    LOAD_CHANNELS_PACKET_FOR_EDIT,
    LOAD_PACKET,
    LOAD_PACKETS
} from "../../constants/ActionTypes";
import {refreshToken} from "./Auth";
import {accessForbidden, internalServerError} from "../../util/config";

export const getPackets = () => async dispatch => {
    dispatch(fetchStart());
    try {
        const res = await httpClient.get(`packet/list/`)
        dispatch({
            type: LOAD_PACKETS,
            payload: res.data
        })
        dispatch(fetchSuccess())
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(refreshToken(getPackets))
        } else if (error.response.status === 403) {
            dispatch(fetchError(accessForbidden))
        } else {
            dispatch(fetchError(internalServerError))
        }
    }
}

export const addPacket = (value) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.post(`packet/`, value);
        dispatch(getPackets())
        dispatch(showMessage('Пакет успешно добавлен'))
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(refreshToken(addPacket, value))
        } else if (error.response.status === 403) {
            dispatch(fetchError(accessForbidden))
        } else {
            dispatch(fetchError(internalServerError))
        }
    }
}

export const delPacket = (value) => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.delete(`packet/${value}/`)
        dispatch({
            type: DELETE_PACKET,
            payload: data
        })
        dispatch(showMessage('Пакет успешно удален'))
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(refreshToken(delPacket, value))
        } else if (error.response.status === 403) {
            dispatch(fetchError(accessForbidden))
        } else {
            dispatch(fetchError(internalServerError))
        }
    }
}

export const updatePacket = (value) => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.patch(`packet/${value.id}/`, value)
        dispatch({
            type: LOAD_PACKET,
            payload: data
        })
        dispatch(showMessage('Пакет успешно изменен'))
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(refreshToken(updatePacket, value))
        } else if (error.response.status === 403) {
            dispatch(fetchError(accessForbidden))
        } else {
            dispatch(fetchError(internalServerError))
        }
    }
}

export const getChannelsForPacket = (value) => async dispatch => {
    dispatch(fetchStart());
    try {
        const res = await httpClient.get(`channel/packet/${value}/`)
        dispatch({
            type: LOAD_CHANNELS_FOR_PACKET,
            payload: {id: value, data: res.data}
        })
        dispatch(fetchSuccess())
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(refreshToken(getChannelsForPacket, value))
        } else if (error.response.status === 403) {
            dispatch(fetchError(accessForbidden))
        } else {
            dispatch(fetchError(internalServerError))
        }
    }
}

export const getChannelsForEditPacketChannel = (value) => async dispatch => {
    dispatch(fetchStart());
    try {
        const res = await httpClient.get(`channel/edit/packet/${value}/`)
        if (res.data) {
            dispatch({
                type: LOAD_CHANNELS_PACKET_FOR_EDIT,
                payload: res.data
            })
        }
        dispatch(fetchSuccess())
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(refreshToken(getChannelsForEditPacketChannel, value))
        } else if (error.response.status === 403) {
            dispatch(fetchError(accessForbidden))
        } else {
            dispatch(fetchError(internalServerError))
        }
    }
}

export const initChannelsForEditPacketChannel = dispatch => {
    dispatch({
        type: LOAD_CHANNELS_PACKET_FOR_EDIT,
        payload: []
    })
}

export const initChannelsForPacket = (value) => dispatch => {
    dispatch({
        type: LOAD_CHANNELS_FOR_PACKET,
        payload: {id: value, data: []}
    })
}