import {LOAD_SETTING_MULTIROOM, SIGNOUT_USER} from "../../constants/ActionTypes";

const initialState = {
    settingMultiRoom: []
}

const SettingMultiRoom = (state = initialState, action) => {
    const {type, payload} = action

    switch (type) {
        case LOAD_SETTING_MULTIROOM:
            return {
                ...state,
                settingMultiRoom: payload
            }

        case SIGNOUT_USER:
            return initialState

        default:
            return state
    }
}

export default SettingMultiRoom;