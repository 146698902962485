import React from 'react';
import {useDispatch} from "react-redux";
import {Button, Form, Input} from "antd";
import {addCountry} from "../../appRedux/actions/PacketCountry";

const AddCountry = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm();

  const onFinish = values => {
    dispatch(addCountry(values));
    form.resetFields()
  };

  return (
    <Form
      form={form}
      initialValues={{remember: true}}
      name="basic"
      onFinish={onFinish}
      className="gx-signin-form gx-form-row0"
      layout="inline"
    >
      <Form.Item
        name="name"
        rules={[{required: true, message: 'Please input name country!'}]}
      >
        <Input placeholder="Name country"/>
      </Form.Item>
      <Form.Item
        name={'code'}
        rules={[{required: true, message: 'Please input code country!'}]}
      >
        <Input placeholder="Code"/>
      </Form.Item>
      <Form.Item>
        <Button className="gx-mb-0"
                type="primary"
                htmlType="submit"
        >
          Создать
        </Button>
      </Form.Item>
    </Form>
  )
};

export default AddCountry;
