import React from 'react';
import {Form, Input, Modal, Select} from "antd";
import {useInvoice} from "../../services/useInvoice";

const Option = Select.Option;

const ModalCreatePaymentClient = ({authUser, dataCountry, visibleModalCreatePaymentClient, setVisibleModalCreatePaymentClient}) => {

    const [form] = Form.useForm();
    const {createClient} = useInvoice()

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const onFinish = async values => {
        await createClient(values)
        setVisibleModalCreatePaymentClient(false)
    };

    const handleCancel = () => {
        setVisibleModalCreatePaymentClient(false)
    }

    return (
        <Modal
            title={`Создание платежного аккаунта`}
            open={visibleModalCreatePaymentClient}
            okText="Создать"
            onOk={form.submit}
            onCancel={handleCancel}
        >
            <Form
                {...layout}
                form={form}
                name="paymentClientCreate"
                onFinish={onFinish}
                className="gx-signin-form gx-form-row0"
            >
                <Form.Item
                    label={'Email'}
                    name="e_mail"
                    initialValue={`${authUser.email}`}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={'First Name'}
                    name="name"
                    initialValue={`${authUser.first_name}`}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={'Select Country'}
                    name="id_country"
                >
                    <Select>
                        {dataCountry.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                </Form.Item>
            </Form>

        </Modal>
    );
};

export default ModalCreatePaymentClient;