import React, {useState} from 'react';
import {Form, InputNumber, Modal, Select, Tooltip} from "antd";
import {useSelector} from "react-redux";

const Option = Select.Option;

const ModalEditAccount = ({text, fromModalEditAccount}) => {

    const {id, email, refunds, credit_price, test_plan, buy_day, restream_server, multiroom, currency_exchange} = text
    const {authUser} = useSelector(state => state.auth);
    const {settingMultiRoom} = useSelector(state => state.settingMultiRoom);
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm();

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const onFinish = values => {
        Object.assign(values, {id: id})
        fromModalEditAccount(values)
        setVisible(false)
        form.resetFields()
    };

    const chowModal = () => {
        setVisible(true)
        form.setFieldsValue({
            test_plan: test_plan.toString(),
            buy_day: buy_day.toString(),
            restream_server: restream_server.toString(),
            currency_exchange: currency_exchange.toString(),
            multiroom: multiroom
        })
    }
    const handleCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    function handleChange(value) {
    }

    return (
        <>
            <Tooltip title={'Редактирование Аккаунта'}>
                <i
                    className="icon icon-edit"
                    onClick={chowModal}
                />
            </Tooltip>
            <Modal
                title={`Редактирование Аккаунта ${email}`}
                open={visible}
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    form={form}
                    name="balance"
                    onFinish={onFinish}
                >
                    {
                        authUser.is_superuser &&
                        <>
                            <Form.Item
                                label={'К-во возвратов'}
                                name="refunds"
                            >
                                <InputNumber style={{marginRight: 0, width: '100%'}} placeholder={refunds} min={0} step={1}/>
                            </Form.Item>
                            <Form.Item
                                label={'Стоимость кредита'}
                                name="credit_price"
                            >
                                <InputNumber style={{ marginRight: 0, width: '100%' }}
                                             placeholder={credit_price}
                                             min={0}
                                             max={10}
                                             step={0.1}
                                />
                            </Form.Item>
                            <Form.Item
                                label={'Конвертация валюты'}
                                name="currency_exchange"
                            >
                                <Select style={{width: '100%'}}>
                                    <Option value="true">Да</Option>
                                    <Option value="false">Нет</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={'Тестовые планы'}
                                name="test_plan"
                            >
                                <Select style={{width: '100%'}}>
                                    <Option value="true">Разрешенно</Option>
                                    <Option value="false">Запрещено</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={'Рестрим сервер'}
                                name="restream_server"
                            >
                                <Select style={{width: '100%'}}>
                                    <Option value="true">Разрешенно</Option>
                                    <Option value="false">Запрещено</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={"Список MultiRoom"}
                                name={"multiroom"}
                                initialValue={multiroom}
                            >
                                <Select
                                    mode="multiple"
                                    style={{width: '100%'}}
                                    onChange={handleChange}
                                >
                                    {settingMultiRoom.map(item => <Option key={item.id} value={item.id}
                                                                          label={item.name}>{item.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </>

                    }
                    <Form.Item
                        label={'Покупки по днях'}
                        name="buy_day"
                    >
                        <Select style={{width: '100%'}}>
                            <Option value="true">Разрешенно</Option>
                            <Option value="false">Запрещено</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ModalEditAccount;