import React, {useEffect} from "react";
import CircularProgress from "../CircularProgress";
import {message as toastNotification} from "antd";
import Auxiliary from "../../util/Auxiliary";
import {useDispatch, useSelector} from "react-redux";
import {hideMessage} from "../../appRedux/actions";

const AppNotificationContainer = () => {

  const dispatch = useDispatch()
  const {error, loading, message} = useSelector(({common}) => common);

  useEffect(()=>{
    if (error){
      toastNotification.error(error);
      dispatch(hideMessage())
    }
    if (message){
      toastNotification.success(message);
      dispatch(hideMessage())
    }
  }, [error, message])

  return (
    <Auxiliary>
      {loading && <div className="gx-loader-view gx-loader-position">
        <CircularProgress/>
      </div>}
    </Auxiliary>
  )
}

export default AppNotificationContainer;
