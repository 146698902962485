import React from 'react';
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";

const CustomSorter = ({sortState, pagination, typeSorter, description}) => {
    return (
        <Tooltip title={'Click to sort'}>
            <div onClick={() => sortState(pagination.orderingManageAccounts, typeSorter)}
                 style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <span>{description}</span>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <CaretUpOutlined style={{
                        fontSize: "12px",
                        lineHeight: "12px",
                        marginBottom: "-2px",
                        color: pagination.orderingManageAccounts ? pagination.orderingManageAccounts === typeSorter ? '#038fde': null : null
                    }}/>
                    <CaretDownOutlined style={{
                        fontSize: "12px",
                        lineHeight: "12px",
                        marginTop: "-2px",
                        color: pagination.orderingManageAccounts ? pagination.orderingManageAccounts.includes(`-${typeSorter}`) ? '#038fde': null : null
                    }}/>
                </div>
            </div>
        </Tooltip>
    );
};

export default CustomSorter;