import {fetchError, fetchStart, fetchSuccess, showMessage} from "./Common";
import {httpClient} from "../../util/Api";
import {LOAD_GROUPS, LOAD_STATUS_SORTED_GROUPS} from "../../constants/ActionTypes";
import {refreshToken} from "./Auth";
import {accessForbidden, internalServerError} from "../../util/config";
import {getChannelsForEditPacketChannel, getPackets} from "./Packet";

export const getGroups = () => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.get(`channel/group/`)
        dispatch({
            type: LOAD_GROUPS,
            payload: data.list
        })
        dispatch({
            type: LOAD_STATUS_SORTED_GROUPS,
            payload: data.sorted
        })
        dispatch(fetchSuccess())
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(getGroups)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}
export const addGroup = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.post(`channel/group/`, values)
        dispatch(getGroups())
        dispatch(showMessage('Группа успешно добавлена'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(addGroup, values)
            : status === 400
                ? fetchError('Группа c таким Id уже существует')
                : status === 403
                    ? fetchError(accessForbidden)
                    : fetchError(internalServerError)
        dispatch(action)
    }
}
export const dropAndDragGroup = (value) => dispatch => {
    dispatch({
        type: LOAD_GROUPS,
        payload: value
    })
}
export const editGroup = (value) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.put(`channel/group/${value.id}/`, value)
        dispatch(getGroups())
        dispatch(getPackets())
        dispatch(showMessage('Группа успешно измененна'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(editGroup, value)
            : status === 400
                ? fetchError('Группа c таким Id уже существует')
                : status === 403
                    ? fetchError(accessForbidden)
                    : fetchError(internalServerError)
        dispatch(action)
    }
}
export const delGroup = (value) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.delete(`channel/group/${value}/`)
        dispatch(getGroups())
        dispatch(getPackets())
        dispatch(showMessage('Группа успешно удаленна'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(delGroup, value)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}
export const saveSortedGroups = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.post(`channel/group/sorted/`, values)
        dispatch(getGroups())
        dispatch(getPackets())
        dispatch(showMessage('Сортировка успешно сохранена'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(saveSortedGroups, values)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}
export const updateChannelsToPacket = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.put(`packet/channels/${values.id}/`, values)
        dispatch(getChannelsForEditPacketChannel(values.id))
        dispatch(showMessage('Действие успешно сохраненно'))
        dispatch(fetchSuccess())
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(updateChannelsToPacket, values)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}
export const removeSortedGroups = () => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.post(`channel/group/remove_sorted/`)
        dispatch(getGroups())
        dispatch(showMessage(data.msg))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(removeSortedGroups)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}