import React from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {Link} from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import {useAuth} from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";

const SignUp = () => {
    const {userSignup} = useAuth();
    const [form] = Form.useForm();

    const onFinishFailed = errorInfo => {
    };

    const onFinish = values => {
        userSignup(values);
        form.resetFields()
    };

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-logo-content">
                        <div className="gx-app-logo-content-bg">
                        </div>
                        <div className="gx-app-logo-wid">
                            <h1><IntlMessages id="app.userAuth.signUp"/></h1>
                            <p><IntlMessages id="app.userAuth.bySigning"/></p>
                            <p><IntlMessages id="app.userAuth.getAccount"/></p>
                        </div>
                        <div className="gx-app-logo">
                            <img alt="example" src="/assets/images/logo.png"/>
                        </div>
                    </div>

                    <div className="gx-app-login-content">
                        <Form
                            form={form}
                            initialValues={{remember: true}}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="gx-signin-form gx-form-row0">
                            <Form.Item style={{marginBottom: 0}}>
                                <Form.Item name="first_name"
                                           rules={[{required: true, message: 'Please input your first name!'}]}
                                           style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
                                >
                                    <Input placeholder="First Name"/>
                                </Form.Item>
                                <Form.Item name="last_name"
                                           rules={[{required: false}]}
                                           style={{
                                               display: 'inline-block',
                                               width: 'calc(50% - 8px)',
                                               margin: '0 8px',
                                               float: 'right'
                                           }}
                                           labelAlign='left'
                                >
                                    <Input placeholder="Last Name"/>
                                </Form.Item>
                            </Form.Item>
                            <Form.Item name="email"
                                       rules={[{
                                           required: true, type: 'email', message: 'The input is not valid E-mail!',
                                       }]}
                            >
                                <Input placeholder="Email"/>
                            </Form.Item>
                            <Form.Item name="password"
                                       rules={[{required: true, message: 'Please input your Password!'}]}
                            >
                                <Input type="password" placeholder="Password"/>
                            </Form.Item>
                            <Form.Item
                                name="re_password"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input type="password" placeholder="Confirm password"/>
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item>
                                <Link style={{float: "right"}} to="/forgot-password">
                                    Forgot password
                                </Link>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" className="gx-mb-0" htmlType="submit">
                                    <IntlMessages id="app.userAuth.signUp"/>
                                </Button>
                                <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
                                id="app.userAuth.signIn"/></Link>
                            </Form.Item>
                        </Form>
                    </div>
                    <AppNotificationContainer/>
                </div>
            </div>
            <div className="visa-card-logo">
                <img alt="Visa" src="/images/visa-mastercard.png"/> {/* Використовуємо імпортоване зображення */}
            </div>
        </div>
    );
};

export default SignUp;
