import React from "react";
import {Button, Form} from "antd";
import IntlMessages from "util/IntlMessages";
import {useAuth} from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import {useParams} from "react-router-dom";

const ConfirmEmailSignUp = () => {
  const {confirmEmailSignUp} = useAuth();
  let { uid, token } = useParams()

  const onFinish = () => {
    confirmEmailSignUp(uid, token);
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.confirmEmail"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{remember: true}}
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0 gx-d-flex gx-justify-content-center">
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.confirmEmail"/>
                </Button>
              </Form.Item>
            </Form>
          </div>
          <AppNotificationContainer />
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmailSignUp;
