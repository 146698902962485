import {fetchError, fetchStart, fetchSuccess} from "./Common";
import {httpClient} from "../../util/Api";
import {LOAD_SETTING_SERVER} from "../../constants/ActionTypes";
import {refreshToken} from "./Auth";
import {accessForbidden, internalServerError} from "../../util/config";

export const getSettingServer = () => async dispatch => {
    dispatch(fetchStart());
    try {
        httpClient
            .get(`setting/server/`)
            .then((res) => {
                dispatch(fetchSuccess())
                dispatch({
                    type: LOAD_SETTING_SERVER,
                    payload: res.data
                })
            })
            .catch(error => {
                if (error.response.status === 401) {
                    dispatch(refreshToken(getSettingServer))
                } else if (error.response.status === 403) {
                    dispatch(fetchError(accessForbidden))
                } else {
                    dispatch(fetchError(internalServerError))
                }
            })
    } catch (e) {
        if (!Boolean(e.response)) {
            dispatch(fetchError(internalServerError))
        }
    }
}

export const addSettingServer = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        httpClient
            .post(`setting/server/`, values)
            .then((res) => {
                dispatch(fetchSuccess())
                dispatch(getSettingServer())
            })
            .catch(error => {
                if (error.response.status === 401) {
                    dispatch(refreshToken(addSettingServer, values))
                } else if (error.response.status === 403) {
                    dispatch(fetchError(accessForbidden))
                } else {
                    dispatch(fetchError(internalServerError))
                }
            })
    } catch (e) {
        if (!Boolean(e.response)) {
            dispatch(fetchError(internalServerError))
        }
    }
}

export const editSettingServer = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        httpClient
            .patch(`setting/server/${values.id}/`, values)
            .then((res) => {
                dispatch(fetchSuccess())
                dispatch(getSettingServer())
            })
            .catch(error => {
                if (error.response.status === 401) {
                    dispatch(refreshToken(editSettingServer, values))
                } else if (error.response.status === 403) {
                    dispatch(fetchError(accessForbidden))
                } else {
                    dispatch(fetchError(internalServerError))
                }
            })
    } catch (e) {
        if (!Boolean(e.response)) {
            dispatch(fetchError(internalServerError))
        }
    }
}

export const deleteSettingServer = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        httpClient
            .delete(`setting/server/${values.id}/`)
            .then((res) => {
                dispatch(fetchSuccess())
                dispatch(getSettingServer())
            })
            .catch(error => {
                if (error.response.status === 401) {
                    dispatch(refreshToken(deleteSettingServer))
                } else if (error.response.status === 403) {
                    dispatch(fetchError(accessForbidden))
                } else {
                    dispatch(fetchError(internalServerError))
                }
            })
    } catch (e) {
        if (!Boolean(e.response)) {
            dispatch(fetchError(internalServerError))
        }
    }
}
