import React from 'react';
import {Button, Card, Form, Input} from "antd";
import {bindAccount} from "../../appRedux/actions";
import {useDispatch} from "react-redux";

const FormBindingAccount = ({pagination}) => {

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const onFinish = values => {
        dispatch(bindAccount({
            dataAccount: values,
            querySetParams: pagination
        }))
        form.resetFields()
    };

    return (
        <Card className="gx-card" title="Форма привязки аккаунта">
            <Form
                form={form}
                name={"bindAccount"}
                onFinish={onFinish}
                layout="inline"
            >
                <Form.Item
                    label={'Email Пользователя'}
                    rules={[{required: true, type: 'email', message: 'Please input email!'}]}
                    name="email"
                >
                    <Input/>
                </Form.Item>
                <Form.Item>
                    <Button className="gx-mb-0"
                            type="primary"
                            htmlType="submit"
                    >
                        Проверить
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default FormBindingAccount;