import React from "react";
import {Button, Form, Input} from "antd";
import IntlMessages from "util/IntlMessages";
import {useAuth} from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import {useParams} from "react-router-dom";

const PasswordResetConfirm = () => {
  const {confirmPasswordReset} = useAuth();
  let { uid, token } = useParams()

  const onFinish = values => {
    confirmPasswordReset(uid, token, values);
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.resetPassword"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{remember: true}}
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0">

              <Form.Item name="password"
                         rules={[{required: true, message: 'Please input your Password!'}]}
              >
                <Input type="password" placeholder="Password"/>
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input type="password" placeholder="Confirm password"/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.resetPassword"/>
                </Button>
              </Form.Item>
            </Form>
          </div>
          <AppNotificationContainer />
        </div>
      </div>
    </div>
  );
};

export default PasswordResetConfirm;
