import {
    LOAD_HISTORY_BALANCE_CUSTOMER,
    LOAD_HISTORY_BALANCE_MANAGER,
    LOAD_HISTORY_CREDIT,
    SIGNOUT_USER
} from "../../constants/ActionTypes";

const initialState = {
    historyBalanceCustomer: {},
    historyBalanceManager: {},
    historyCredit: {}
};

const HistoryBalanceReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case LOAD_HISTORY_BALANCE_CUSTOMER: {
            return {
                ...state,
                historyBalanceCustomer: payload
            }
        }
        case LOAD_HISTORY_BALANCE_MANAGER: {
            return {
                ...state,
                historyBalanceManager: payload
            }
        }
        case LOAD_HISTORY_CREDIT: {
            return {
                ...state,
                historyCredit: payload
            }
        }
        case SIGNOUT_USER:
            return initialState
        default:
            return state;
    }
}

export default HistoryBalanceReducer;