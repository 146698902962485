import {useDispatch, useSelector} from "react-redux";
import {Button, Modal, Space, Table, Tooltip} from "antd";
import React, {useState} from "react";
import {sortableContainer, sortableElement, sortableHandle} from "react-sortable-hoc";
import {CheckCircleTwoTone, ExclamationCircleOutlined, MenuOutlined} from "@ant-design/icons";
import {arrayMoveImmutable} from "array-move";


import ModalEditGroup from "./ModalEditGroup";
import {
    delGroup,
    dropAndDragGroup,
    editGroup,
    removeSortedGroups,
    saveSortedGroups
} from "../../appRedux/actions/PacketGroup";

const {Column} = Table;
const {confirm} = Modal;

const DragHandle = sortableHandle(() => <MenuOutlined style={{cursor: 'grab', color: '#999'}}/>);

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const ListGroups = () => {

    const {authUser} = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const {groups, sortedGroups} = useSelector(state => state.packet);

    const [statusSave, setStatusSave] = useState(true);
    const [arr, setArr] = useState([]);

    const showConfirmActive = () => {
        confirm({
            title: 'Вы хотите очистить сортировку групп?',
            icon: <ExclamationCircleOutlined/>,
            content: 'После очистки сортировки вам будет доступна сортировка по умолчанию',
            onOk() {
                dispatch(removeSortedGroups())
            },
            onCancel() {
            },
        });
    }
    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(groups), oldIndex, newIndex).filter(el => !!el);
            const sortedArr = newData.map((number, index) => {
                const container = {};

                container.id = number.id
                container.account = authUser.id;
                container.group = number.group;
                container.sorted_index = index;

                return container;
            })
            setArr(sortedArr)
            dispatch(dropAndDragGroup(newData));
            if (statusSave === true) {
                setStatusSave(!statusSave)
            }
        }
    };

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({className, style, ...restProps}) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = groups.findIndex(x => x.sorted_index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    return (
        <>
            <Table
                rowKey="sorted_index"
                className="gx-table-responsive"
                dataSource={groups.filter((item => authUser.is_superuser ? item : item.active === true))}
                pagination={false}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
                size="small"
            >
            <Column title="Sort"
                    dataIndex="sort"
                    align={'center'}
                    key="sort"
                    width={30}
                    render={() => <DragHandle/>}
            />
            {
                authUser.is_superuser &&
                <Column title=""
                        dataIndex="active"
                        align={'center'}
                        key="active"
                        width={20}
                        render={value => (
                            <>
                                {
                                    value &&
                                    <CheckCircleTwoTone twoToneColor="#52c41a"/>
                                }
                            </>
                        )}
                />
            }
            <Column title="Название Группы" dataIndex="name" key="name"/>
            {
                authUser.is_superuser &&
                <Column
                    title="Actions"
                    width={100}
                    align={'center'}
                    dataIndex=""
                    key="x"
                    render={(text, record) => (
                        <Space size="small">
                            <Tooltip title={'Состояние группы'}>
                                <i
                                    className="icon icon-check"
                                    onClick={() => dispatch(editGroup({
                                        active: !record.active,
                                        id: record.group
                                    }))}
                                />
                            </Tooltip>
                            <ModalEditGroup group={text.group} name={text.name}/>
                            <Tooltip title="Удалить группу">
                                <i
                                    className="icon icon-trash"
                                    onClick={() => dispatch(delGroup(record.group))}
                                />
                            </Tooltip>
                        </Space>
                    )}
                />
            }
            </Table>
            <Button disabled={statusSave}
                    style={{marginTop: 20}}
                    onClick={() => {
                        setStatusSave(!statusSave)
                        dispatch(saveSortedGroups(arr))
                    }}>
                Save
            </Button>
            <Button disabled={!sortedGroups}
                    style={{marginTop: 20}}
                    onClick={() => {
                        showConfirmActive()
                    }}>
                Очистить сортировку
            </Button>
        </>
    )
};

export default ListGroups;
