import {fetchError, fetchStart, fetchSuccess, showMessage} from "./Common";
import {httpClient} from "../../util/Api";
import {getAuthUserReducer, refreshToken} from "./Auth";
import {accessForbidden, internalServerError} from "../../util/config";
import {
    LOAD_HISTORY_BALANCE_CUSTOMER,
    LOAD_HISTORY_BALANCE_MANAGER,
    LOAD_HISTORY_CREDIT,
    LOAD_MANAGE_ACCOUNT,
} from "../../constants/ActionTypes";
import {getManageAccount} from "./Customer";

export const getHistoryBalanceCustomer = (values) => async (dispatch) => {
    try {
        const str = new URLSearchParams(values).toString();
        dispatch(fetchStart());
        const {data} = await httpClient.get(`balance/customer?${str}`);
        dispatch({
            type: LOAD_HISTORY_BALANCE_CUSTOMER,
            payload: data,
        });
        dispatch(fetchSuccess());
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    dispatch(refreshToken(getHistoryBalanceCustomer, values));
                    break;
                case 403:
                    dispatch(fetchError(accessForbidden));
                    break;
                default:
                    dispatch(fetchError(internalServerError));
                    break;
            }
        } else {
            dispatch(fetchError(internalServerError));
        }
    }
};

export const getHistoryBalanceManager = (values) => async (dispatch) => {
    try {
        const str = new URLSearchParams(values).toString();
        dispatch(fetchStart());
        const {data} = await httpClient.get(`balance/manager?${str}`);
        dispatch({
            type: LOAD_HISTORY_BALANCE_MANAGER,
            payload: data,
        });
        dispatch(fetchSuccess());
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    dispatch(refreshToken(getHistoryBalanceManager, values));
                    break;
                case 403:
                    dispatch(fetchError(accessForbidden));
                    break;
                default:
                    dispatch(fetchError(internalServerError));
                    break;
            }
        } else {
            dispatch(fetchError(internalServerError));
        }
    }
};

export const getHistoryCredit = (values) => async (dispatch) => {
    const params = new URLSearchParams(values).toString();
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.get(`balance/credit?${params}`);
        dispatch({
            type: LOAD_HISTORY_CREDIT,
            payload: data,
        });
        dispatch(fetchSuccess());
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    dispatch(refreshToken(getHistoryCredit, values));
                    break;
                case 403:
                    dispatch(fetchError(accessForbidden));
                    break;
                default:
                    dispatch(fetchError(internalServerError));
                    break;
            }
        } else {
            dispatch(fetchError(internalServerError));
        }
    }
};

export const addBalance = (value) => async (dispatch) => {
    const {id, action} = value;
    try {
        dispatch(fetchStart());
        const {data} = await httpClient.post(`balance/add/${id}/`, value);
        dispatch({
            type: LOAD_MANAGE_ACCOUNT,
            payload: data
        });
        dispatch(showMessage(action === 'true'
            ? 'Успешное пополнение баланса'
            : 'Успешное снятие баланса'));
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(refreshToken(addBalance, value));
        } else if (error.response.status === 402) {
            dispatch(fetchError('Недостаточно средств'));
        } else if (error.response.status === 403) {
            dispatch(fetchError(accessForbidden));
        } else {
            dispatch(fetchError(internalServerError));
        }
    }
};

export const transferBalance = (values) => async (dispatch) => {
    dispatch(fetchStart());
    try {
        await httpClient.post(`balance/transfer/${values.id}/`, values);
        dispatch(showMessage('Успешный перевод средств'));
        dispatch(getManageAccount({page: 1, page_size: 20}));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    dispatch(refreshToken(transferBalance, values));
                    break;
                case 402:
                    dispatch(fetchError('Недостаточно средств'));
                    break;
                case 403:
                    dispatch(fetchError(accessForbidden));
                    break;
                default:
                    dispatch(fetchError(internalServerError));
                    break;
            }
        } else {
            dispatch(fetchError(internalServerError));
        }
    }
};

export const repaymentMoney = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.post('balance/repayment', values)
        dispatch(getAuthUserReducer())
        dispatch(showMessage('Успешный перевод средств'))
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    dispatch(refreshToken(repaymentMoney, values));
                    break;
                case 402:
                    dispatch(fetchError('Недостаточно средств'));
                    break;
                case 403:
                    dispatch(fetchError(accessForbidden));
                    break;
                default:
                    dispatch(fetchError(internalServerError));
                    break;
            }
        } else {
            dispatch(fetchError(internalServerError));
        }
    }
}
