import React, {useState} from 'react';
import {Form, InputNumber, Modal, Tooltip} from "antd";
import {useDispatch} from "react-redux";

import {transferBalance} from "../../appRedux/actions/Balance";

const ModalTransferBalance = ({text}) => {

    const {id, email} = text
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false)

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const onFinish = values => {
        values.id = id
        dispatch(transferBalance(values))
        form.resetFields()
        setVisible(false)
    };

    const chowModal = () => {
        setVisible(true)
        form.setFieldsValue({
            amount: '1'
        })
    }
    const handleCancel = () => {
        setVisible(false)
    }

    return (
        <>
            <Tooltip title={'Перевод средств'}>
                <i
                    className="icon icon-revenue-new"
                    onClick={chowModal}
                />
            </Tooltip>
            <Modal
                title={`Перевод средств для ${email}`}
                visible={visible}
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    form={form}
                    name="transfer"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="amount"
                        label="Перевод средств"
                    >
                        <InputNumber style={{marginRight: 0, width: '100%'}}
                                     min={0}
                                     step="0.01"
                                     stringMode
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ModalTransferBalance;