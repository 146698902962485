// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'USER_DATA';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const UPDATE_LOAD_USER = 'UPDATE_LOAD_USER';

export const IS_LOADING_USER = 'IS_LOADING_USER';

//Account
export const LOAD_MANAGE_ACCOUNT = 'LOAD_MANAGE_ACCOUNT';
export const LOAD_MANAGE_ACCOUNTS = 'LOAD_MANAGE_ACCOUNTS';
export const LOAD_MANAGE_ACCOUNT_FOR_FILTER = 'LOAD_MANAGE_ACCOUNT_FOR_FILTER';

//History Balance
export const LOAD_HISTORY_BALANCE_CUSTOMER = 'LOAD_HISTORY_BALANCE_CUSTOMER';
export const LOAD_HISTORY_BALANCE_MANAGER = 'LOAD_HISTORY_BALANCE_MANAGER';
export const LOAD_HISTORY_CREDIT = 'LOAD_HISTORY_CREDIT';

//Customer
export const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS';
export const LOAD_CUSTOMERS_ALL = 'LOAD_CUSTOMERS_ALL';
export const LOAD_CUSTOMERS_ADMIN_MANAGER = 'LOAD_CUSTOMERS_ADMIN_MANAGER';
export const LOAD_CUSTOMERS_ADMIN_MANAGER_ALL = 'LOAD_CUSTOMERS_ADMIN_MANAGER_ALL';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_ADMIN_MANAGER = 'DELETE_CUSTOMER_ADMIN_MANAGER';

//Packet
export const LOAD_PACKET = 'LOAD_PACKET';
export const LOAD_PACKETS = 'LOAD_PACKETS';
export const DELETE_PACKET = 'DELETE_PACKET';
export const LOAD_GROUPS = 'LOAD_GROUPS';
export const LOAD_STATUS_SORTED_GROUPS = 'LOAD_STATUS_SORTED_GROUPS';
export const LOAD_COUNTRY = 'LOAD_COUNTRY';
export const LOAD_STATUS_SORTED_COUNTRY = 'LOAD_STATUS_SORTED_COUNTRY';
export const LOAD_CHANNELS = 'LOAD_CHANNELS';
export const ADD_CHANNEL = 'ADD_CHANNEL';
export const EDIT_CHANNEL = 'EDIT_CHANNEL';
export const DELETE_CHANNEL = 'DELETE_CHANNEL';
export const LOAD_STATUS_SORTED_CHANNELS = 'LOAD_STATUS_SORTED_CHANNELS';
export const LOAD_CHANNELS_FOR_PACKET = 'LOAD_CHANNELS_FOR_PACKET';
export const LOAD_CHANNELS_PACKET_FOR_EDIT = 'LOAD_CHANNELS_PACKET_FOR_EDIT';

//Settings
export const LOAD_SETTING_SERVER = 'LOAD_SETTING_SERVER';
export const LOAD_SETTING_MULTIROOM = 'LOAD_SETTING_MULTIROOM';
