import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form, Input, InputNumber, Modal, Select, Tooltip} from "antd";
import {updatePacket} from "../../appRedux/actions/Packet";

const Option = Select.Option;

function updateSettingMultiRoom(settingMultiRoom, multiroom_data) {

    if (!multiroom_data) {
        return settingMultiRoom;
    }

    const multiroom_id = multiroom_data.id;
    const existingItem = settingMultiRoom.find(item => item.id === multiroom_id);

    if (existingItem) {
        return settingMultiRoom;
    } else {
        multiroom_data.disabled = true
        return [...settingMultiRoom, multiroom_data];
    }
}

const ModalEditPacket = ({text}) => {

    const {name, price, id, multiroom, multiroom_data, set_group, set_country} = text
    const dispatch = useDispatch()
    const {settingMultiRoom} = useSelector(state => state.settingMultiRoom);
    const {countries, groups} = useSelector(state => state.packet)
    const [form] = Form.useForm();

    const [visible, setVisible] = useState(false)

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const onFinish = values => {
        values.id = id
        dispatch(updatePacket(values));
        setVisible(false)
        form.resetFields()
    };

    const chowModal = () => {
        form.setFieldsValue({
            name: name,
            price: price,
            multiroom: multiroom,
            set_group: set_group,
            set_country: set_country,
        })
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    function handleChange(value) {

    }

    const updatedSettingMultiRoom = updateSettingMultiRoom(
        settingMultiRoom,
        multiroom_data
    );

    return (
        <>
            <Tooltip
                title="Настройки пакета">
                <i
                    className="icon icon-edit"
                    onClick={chowModal}
                />
            </Tooltip>
            <Modal
                title={`Настройки пакета -- ${name}`}
                open={visible}
                okText="Изменить"
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{remember: true}}
                    name="packet"
                    onFinish={onFinish}
                    className="gx-signin-form gx-form-row0"
                >
                    <Form.Item
                        label={'Name packet'}
                        initialValue={`${name}`}
                        name="name"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={'Price packet'}
                        initialValue={price}
                        name="price"
                    >
                        <InputNumber style={{marginRight: 0, width: '100%'}}
                                     placeholder="Price"
                                     min={0}
                                     max={10}
                                     step={0.1}
                        />
                    </Form.Item>
                    <Form.Item
                        label={"Список групп"}
                        name={"set_group"}
                        initialValue={set_group}
                    >
                        <Select
                            mode="multiple"
                            style={{width: '100%'}}
                            onChange={handleChange}
                        >
                            {groups.map(item => <Option key={item.group} value={item.group}
                                                        label={item.name}>{item.name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={"Список локаций"}
                        name={"set_country"}
                        initialValue={set_country}
                    >
                        <Select
                            mode="multiple"
                            style={{width: '100%'}}
                            onChange={handleChange}
                        >
                            {countries.map(item => <Option key={item.country} value={item.country}
                                                           label={item.code}>{item.code}</Option>)}
                        </Select>
                    </Form.Item>
                    {
                        updatedSettingMultiRoom.length > 0 &&
                        <Form.Item
                            label={"Список MultiRoom"}
                            name={"multiroom"}
                        >
                            <Select
                                style={{width: '100%'}}
                            >
                                <Option value={""} label={""}></Option>
                                {
                                    updatedSettingMultiRoom.map(item => <Option
                                        key={item.id}
                                        value={item.id}
                                        lable={item.name}
                                        disabled={item.disabled ? item.disabled : false}
                                    >
                                        {item.name}
                                    </Option>)
                                }
                            </Select>
                        </Form.Item>
                    }
                </Form>
            </Modal>
        </>
    );
};

export default ModalEditPacket;