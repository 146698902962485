import {Button, Form, Input} from "antd";
import React from "react";
import {useDispatch} from "react-redux";
import {addGroup} from "../../appRedux/actions/PacketGroup";

const AddGroup = () => {

  const dispatch = useDispatch()
  const [form] = Form.useForm();

  const onFinish = values => {
    dispatch(addGroup(values));
    form.resetFields()
  };

  return (
    <Form
      form={form}
      initialValues={{remember: true}}
      name="basic"
      onFinish={onFinish}
      className="gx-signin-form gx-form-row0"
      layout="inline"
    >
      <Form.Item
        name="name"
        rules={[{required: true, message: 'Please input name group!'}]}
      >
        <Input placeholder="Name group"/>
      </Form.Item>
      <Form.Item>
        <Button className="gx-mb-0"
                type="primary"
                htmlType="submit"
        >
          Создать
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AddGroup
