import React, {useState} from 'react';
import {Card, Tabs} from "antd";
import {useSelector} from "react-redux";

import TableTabBalanceManager from "./TableTabBalanceManager";
import TableTabBalanceCustomer from "./TableTabBalanceCustomer";
import TableTabCredit from "./TableTabCredit";
import AppNotificationContainer from "../../components/AppNotificationContainer";

const TabPane = Tabs.TabPane;

const BalanceComponent = () => {
    const {authUser} = useSelector(state => state.auth);
    const [stateTab, setStateTab] = useState({key: 'tab1'})

    const onTabChange = (key, type) => {
        setStateTab({[type]: key});
    };

    return (
        <>
            <Card title={'История баланса'}>
                {
                    authUser.is_staff ?
                        <Tabs defaultActiveKey={stateTab} onChange={(key) => (onTabChange(key, 'key'))}>
                            <TabPane tab="Мой баланс" key="tab1">
                                <TableTabBalanceCustomer stateTab={stateTab}/>
                            </TabPane>
                            <TabPane tab="Прикрепленные аккаунты" key="tab2">
                                <TableTabBalanceManager stateTab={stateTab}/>
                            </TabPane>
                            <TabPane tab="Взаиморасчет" key="tab3">
                                <TableTabCredit stateTab={stateTab}/>
                            </TabPane>
                        </Tabs>
                        :
                        <TableTabBalanceCustomer stateTab={stateTab}/>
                }
            </Card>
            <AppNotificationContainer/>
        </>
    );
};

export default BalanceComponent;