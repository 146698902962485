import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import PacketsComponent from "./Packet";
import BalanceComponent from "./Balance";
import AccountComponent from "./Account";
import InvoiceComponent from "./Invoice";

const App = ({match}) => (
    <div className="gx-main-content-wrapper">
        <Switch>
            <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
            <Route path={`${match.url}chat`} component={asyncComponent(() => import('./Chat/index'))}/>
            <Route path={`${match.url}account`}><AccountComponent/></Route>
            <Route path={`${match.url}invoice`}><InvoiceComponent/></Route>
            <Route path={`${match.url}balance`}><BalanceComponent/></Route>
            <Route path={`${match.url}customers`} component={asyncComponent(() => import('./Customer/index'))}/>
            <Route path={`${match.url}logging`} component={asyncComponent(() => import('./Logging/index'))}/>
            <Route path={`${match.url}packets`}><PacketsComponent/></Route>
            <Route path={`${match.url}settings`} component={asyncComponent(() => import('./Settings/index'))}/>
        </Switch>
    </div>
);

export default App;
