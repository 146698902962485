import React, {useEffect, useState} from "react";
import AppNotificationContainer from "../../components/AppNotificationContainer";
import PaymentInfo from "./PaymentInfo";
import ListInvoices from "./ListInvoices";
import {useInvoice} from "../../services/useInvoice";
import {useSelector} from "react-redux";

const InvoiceComponent = () => {

    const {authUser} = useSelector(state => state.auth)
    const [dataCountry, setDataCountry] = useState([])
    const [dataInvoices, setDataInvoices] = useState([])
    const [dataClient, setDataClient] = useState([])
    const {getCountry, getInvoices, getClient} = useInvoice()

    const fetchDataGetInvoices = async () => {
        const items = await getInvoices()
        setDataInvoices(items)
    }

    const fetchDataGetClient = async () => {
        const itemsGet = await getClient();
        setDataClient(itemsGet)
        fetchDataGetInvoices()
    };

    useEffect(() => {
        const fetchData = async () => {
            const items = await getCountry();
            if (Object.keys(items).length > 0) {
                setDataCountry(items);
                if (authUser.payment_id_client) {
                    fetchDataGetClient()
                }
            }
        };
        fetchData();
    }, [authUser])

    return (
        <>
            <PaymentInfo
                dataCountry={dataCountry}
                dataClient={dataClient}
                setDataClient={setDataClient}
            />
            <ListInvoices
                dataInvoices={dataInvoices}
                setDataInvoices={setDataInvoices}
            />
            <AppNotificationContainer/>
        </>
    )
}

export default InvoiceComponent;