const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'russian',
    locale: 'ru',
    name: 'Russian',
    icon: 'ru'
  },
  {
    languageId: 'deutsch',
    locale: 'de',
    name: 'Deutsch',
    icon: 'de'
  },
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Spanish',
    icon: 'es'
  },
  {
    languageId: 'french',
    locale: 'fr',
    name: 'French',
    icon: 'fr'
  },
  {
    languageId: 'italian',
    locale: 'it',
    name: 'Italian',
    icon: 'it'
  }
];
export default languageData;
