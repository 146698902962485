import React, {useState} from 'react';
import {Col, Row} from "antd";
import {useSelector} from "react-redux";

import FormChangePassword from "./FormChangePassword";
import FormPersonalData from "./FormPersonalData";
import FormBindingAccount from "./FormBindingAccount";
import TableManageAccount from "./TableManageAccount";
import AppNotificationContainer from "../../components/AppNotificationContainer";

const AccountComponent = () => {

    const {pageSize} = useSelector(({settings}) => settings);
    const [pagination, setPagination] = useState({
        page: 1,
        page_size: pageSize,
        orderingManageAccounts: localStorage.getItem('orderingManageAccounts') ? localStorage.getItem('orderingManageAccounts') : null,
    })

    const {authUser} = useSelector(state => state.auth)

    return (
        <>
            <Row>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormChangePassword/>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormPersonalData/>
                </Col>
            </Row>
            {
                !authUser.is_superuser && authUser.is_staff &&
                <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <FormBindingAccount pagination={pagination}/>
                    </Col>
                </Row>
            }
            {
                authUser.is_staff &&
                <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <TableManageAccount pagination={pagination} setPagination={setPagination}/>
                    </Col>
                </Row>
            }
            <AppNotificationContainer/>
        </>
    );
};

export default AccountComponent;