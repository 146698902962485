import {LOAD_SETTING_SERVER, SIGNOUT_USER} from "../../constants/ActionTypes";

const initialState = {
    settingServer: []
}

const SettingsServer = (state = initialState, action) => {
    const {type, payload} = action

    switch (type) {
        case LOAD_SETTING_SERVER:
            return {
                ...state,
                settingServer: payload
            }

        case SIGNOUT_USER:
            return initialState

        default:
            return state
    }
}

export default SettingsServer;