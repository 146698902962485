import React, {useEffect} from 'react';
import {Form, Input, Row, Col, Select, Button} from "antd";
import {useInvoice} from "../../services/useInvoice";
import {useSelector} from "react-redux";

const Option = Select.Option;

const ClientPaymentInfo = ({dataCountry, dataClient, setDataClient}) => {

    const [form] = Form.useForm();
    const {authUser} = useSelector(state => state.auth)
    const {updateClient} = useInvoice()

    useEffect(() => {
        if (dataClient && Object.keys(dataClient).length !== 0) {
            const modifyItemsGet = {...dataClient, 'id_country': dataClient.country.id}
            form.setFieldsValue(modifyItemsGet);
        }
    }, [dataClient])

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const onFinish = async values => {
        const fetchDataUpdateClient = async () => {
            const itemsUpdate = await updateClient({...values, 'id_client': authUser.payment_id_client});
            if (itemsUpdate && Object.keys(itemsUpdate).length !== 0) {
                setDataClient(itemsUpdate);
                const modifyItemsUpdate = {...itemsUpdate, 'id_country': itemsUpdate.country.id}
                form.setFieldsValue(modifyItemsUpdate);
            }
        }
        fetchDataUpdateClient();
    };

    return (
        <Form
            {...layout}
            form={form}
            name="paymentClientInfo"
            onFinish={onFinish}
            className="gx-signin-form gx-form-row0"
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label={'Email'}
                        name="e_mail"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your current email!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={'Postal Code'}
                        name="postal_code"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label={'First Name'}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your current name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={'Address'}
                        name="address"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label={'Country'}
                        name="id_country"
                        rules={[
                            {
                                required: true,
                                message: 'Please select your current country!',
                            },
                        ]}
                    >
                        <Select>
                            {dataCountry.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={'Phone'}
                        name="phone"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{textAlign: 'right', marginTop: '16px' }}>
                    <Button type="primary" htmlType="submit">
                        Обновить платежный аккаунт
                    </Button>

                </Col>
            </Row>
        </Form>
    );
};

export default ClientPaymentInfo;