import {
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_ADMIN_MANAGER,
    LOAD_CUSTOMERS,
    LOAD_CUSTOMERS_ADMIN_MANAGER,
    LOAD_CUSTOMERS_ALL,
    LOAD_CUSTOMERS_ADMIN_MANAGER_ALL,
    LOAD_MANAGE_ACCOUNT,
    LOAD_MANAGE_ACCOUNTS,
    LOAD_MANAGE_ACCOUNT_FOR_FILTER,
    SIGNOUT_USER
} from "../../constants/ActionTypes";

const initialState = {
    customersId: {},
    customersManager: {},
    manageAccounts: {},
    manageAccountForFilter: []
}

const Customer = (state = initialState, action) => {
    const {type, payload} = action

    switch (type) {
        case LOAD_CUSTOMERS_ALL:
            return {
                ...state,
                customersId: payload,
            }
        case LOAD_CUSTOMERS:
            const updatedCustomersId = [...state.customersId.results];
            payload.forEach((newCustomer) => {
                const indexForEditCustomer = updatedCustomersId.findIndex(customer => customer.id === newCustomer.id);
                if (indexForEditCustomer !== -1) {
                    updatedCustomersId[indexForEditCustomer] = newCustomer;
                }
            })
            return {
                ...state,
                customersId: {...state.customersId, results: updatedCustomersId},
            }
        case DELETE_CUSTOMER:
            const updateStateCustomers = state.customersId.results.filter(item => item.id !== payload.id)
            return {
                ...state,
                customersId: {...state.customersId, results: updateStateCustomers}
            }
        case LOAD_CUSTOMERS_ADMIN_MANAGER_ALL:
            return {
                ...state,
                customersManager: payload,
            }
        case LOAD_CUSTOMERS_ADMIN_MANAGER:
            const updatedCustomersManager = [...state.customersManager.results];
            payload.forEach(newCustomer => {
                const indexForEditManager = updatedCustomersManager.findIndex(customer => customer.id === newCustomer.id);
                if (indexForEditManager !== -1) {
                    updatedCustomersManager[indexForEditManager] = newCustomer;
                }
            });
            return {
                ...state,
                customersManager: {
                    ...state.customersManager, results: updatedCustomersManager
                },
            };
        case DELETE_CUSTOMER_ADMIN_MANAGER:
            const updateStateCustomersManager = state.customersManager.results.filter(item => item.id !== payload.id)
            return {
                ...state,
                customersManager: {...state.customersManager, results: updateStateCustomersManager}
            }
        case LOAD_MANAGE_ACCOUNT:
            const indexForEditAccount = state.manageAccounts.results.findIndex(account => account.id === payload.id);
            if (indexForEditAccount !== -1) {
                const updatedAccount = {
                    ...state.manageAccounts.results[indexForEditAccount],
                    ...payload
                };
                const updatedResults = [...state.manageAccounts.results];
                updatedResults[indexForEditAccount] = updatedAccount;
                return {
                    ...state,
                    manageAccounts: {...state.manageAccounts, results: updatedResults}
                };
            }
            return state;
        case LOAD_MANAGE_ACCOUNTS:
            return {
                ...state,
                manageAccounts: payload
            }
        case LOAD_MANAGE_ACCOUNT_FOR_FILTER:
            return {
                ...state,
                manageAccountForFilter: payload
            }
        case SIGNOUT_USER:
            return initialState
        default:
            return state
    }
}

export default Customer;
