import {INIT_URL, SWITCH_LANGUAGE} from "../../constants/ActionTypes";
import {
    LAYOUT_TYPE,
    LAYOUT_TYPE_FRAMED,
    NAV_STYLE,
    NAV_STYLE_FIXED, PAGE_SIZE,
    THEME_COLOR,
    THEME_TYPE,
    THEME_TYPE_DARK, UPDATE_RTL_STATUS
} from "../../constants/ThemeSetting";

const baseLang = {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
}

const initialSettings = {
    navStyle: NAV_STYLE_FIXED,
    layoutType: LAYOUT_TYPE_FRAMED,
    themeType: localStorage.getItem('theme') ? localStorage.getItem('theme') : THEME_TYPE_DARK,
    themeColor: '',
    pageSize: localStorage.getItem('pageSize') ? localStorage.getItem('pageSize') : 20,
    isDirectionRTL: false,
    locale: localStorage.getItem('locale') ? JSON.parse(localStorage.getItem('locale')) : baseLang,
    initURL: '',
};

const SettingsReducer = (state = initialSettings, action) => {
    switch (action.type) {

        case THEME_TYPE:
            return {
                ...state,
                themeType: action.themeType
            };
        case THEME_COLOR:
            return {
                ...state,
                themeColor: action.themeColor
            };

        case UPDATE_RTL_STATUS:
            return {
                ...state,
                isDirectionRTL: action.rtlStatus
            };

        case NAV_STYLE:
            return {
                ...state,
                navStyle: action.navStyle
            };
        case LAYOUT_TYPE:
            return {
                ...state,
                layoutType: action.layoutType
            };

        case SWITCH_LANGUAGE:
            return {
                ...state,
                locale: action.payload,

            };

        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }

        case PAGE_SIZE: {
            return {
                ...state,
                pageSize: action.payload
            }
        }

        default:
            return state;
    }
};

export default SettingsReducer;
