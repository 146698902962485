import React from "react";
import {Button, Form, Input} from "antd";
import IntlMessages from "util/IntlMessages";
import {useAuth} from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";

const PasswordResetEmail = () => {
  const {sendPasswordResetEmail} = useAuth();

  const onFinish = values => {
    sendPasswordResetEmail(values);
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.forgotPassword"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{remember: true}}
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0">
              <Form.Item
                initialValue=""
                rules={[{required: true, message: 'The input is not valid E-mail!'}]} name="email">
                <Input placeholder="Email"/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.forgotPassword"/>
                </Button>
              </Form.Item>
            </Form>
          </div>
          <AppNotificationContainer />
        </div>
      </div>
    </div>
  );
};

export default PasswordResetEmail;
