import {fetchError, fetchStart, fetchSuccess, showMessage} from "./Common";
import {httpClient} from "../../util/Api";
import {LOAD_COUNTRY, LOAD_STATUS_SORTED_COUNTRY} from "../../constants/ActionTypes";
import {refreshToken} from "./Auth";
import {accessForbidden, internalServerError} from "../../util/config";
import {getPackets} from "./Packet";

export const getCountry = () => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.get(`channel/country/`)
        dispatch({
            type: LOAD_COUNTRY,
            payload: data.list
        })
        dispatch({
            type: LOAD_STATUS_SORTED_COUNTRY,
            payload: data.sorted
        })
        dispatch(fetchSuccess())
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(getCountry)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}
export const addCountry = (value) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.post(`channel/country/`, value)
        dispatch(getCountry())
        dispatch(showMessage('Страна успешно добавлена'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(addCountry, value)
            : status === 400
                ? fetchError('Страна c таким Id уже существует')
                : status === 403
                    ? fetchError(accessForbidden)
                    : fetchError(internalServerError)
        dispatch(action)
    }
}
export const dropAndDragCountry = (value) => async dispatch => {
    dispatch({
        type: LOAD_COUNTRY,
        payload: value
    })
}
export const editCountry = (value) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.put(`channel/country/${value.id}/`, value)
        dispatch(getCountry())
        dispatch(getPackets())
        dispatch(showMessage('Страна успешно измененна'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(editCountry, value)
            : status === 400
                ? fetchError('Страна c таким Id уже существует')
                : status === 403
                    ? fetchError(accessForbidden)
                    : fetchError(internalServerError)
        dispatch(action)
    }
}
export const delCountry = (value) => async dispatch => {
    dispatch(fetchStart());
    try {
        await httpClient.delete(`channel/country/${value}/`)
        dispatch(getCountry())
        dispatch(getPackets())
        dispatch(showMessage('Страна успешно удаленна'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(delCountry, value)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}

export const saveSortedCountry = (values) => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.post(`channel/country/sorted/`, values)
        dispatch(getCountry())
        dispatch(getPackets())
        dispatch(showMessage(data.msg))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(saveSortedCountry, values)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}

export const removeSortedCountry = () => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.post(`channel/country/remove_sorted/`)
        dispatch(getCountry())
        dispatch(showMessage(data.msg))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(removeSortedCountry)
            : status === 403
                ? fetchError(accessForbidden)
                : fetchError(internalServerError)
        dispatch(action)
    }
}