import {
    IS_LOADING_USER, SIGNOUT_USER, USER_DATA
} from "../../constants/ActionTypes";

const INIT_STATE = {
    isLoadingUser: true,
    authUser: null
};

const AuthReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case IS_LOADING_USER: {
            return {
                ...state,
                isLoadingUser: action.payload
            }
        }
        case USER_DATA: {
            return {
                ...state,
                authUser: action.payload
            }
        }
        case SIGNOUT_USER:
            return INIT_STATE
        default:
            return state;
    }
}

export default AuthReducer;
