import {IS_LOADING_USER, LOAD_MANAGE_ACCOUNT, SIGNOUT_USER, USER_DATA} from "../../constants/ActionTypes";
import {httpClient} from "../../util/Api";
import {fetchError, fetchStart, fetchSuccess, showMessage} from "./Common";
import {getManageAccount} from "./Customer";
import {internalServerError} from "../../util/config";
import {removeAllFalseValues} from "../../util/removeAllFalseValues";

export const signOutContext = (message) => dispatch => {
    httpClient.defaults.headers.common['Authorization'] = '';
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    dispatch({
        type: SIGNOUT_USER
    })
    dispatch(showMessage(message));
    dispatch(isLoadingUser(false));
}

export const refreshToken = (callbackFun, values) => async dispatch => {
    try {
        if (localStorage.getItem('refresh')) {
            const body = JSON.stringify({refresh: localStorage.getItem('refresh')});
            const res = await httpClient.post('auth/jwt/refresh/', body);
            if (res.data) {
                localStorage.setItem('access', res.data.access);
                localStorage.setItem('refresh', res.data.refresh);
                httpClient.defaults.headers.common['Authorization'] = 'JWT ' + res.data.access;
                if (callbackFun) dispatch(callbackFun(values));
            }
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(signOutContext('Сессия истекла'));
        }
    }
};

export const getAuthUserContext = (data) => async dispatch => {
    dispatch({
        type: USER_DATA,
        payload: data
    })
}

export const getAuthUserReducer = () => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.get('auth/users/me/');
        dispatch(getAuthUserContext(data));
        dispatch(fetchSuccess());
    } catch (error) {
        if (error.response && error.response.status === 401) {
            await refreshToken(getAuthUserReducer);
        } else {
            dispatch(signOutContext('Сессия истекла'));
        }
    }
};

export const bindAccount = (value) => async (dispatch) => {
    dispatch(fetchStart());
    try {
        const {dataAccount, querySetParams} = value;
        await httpClient.put(`user/bind_account/`, dataAccount);
        dispatch(getManageAccount(querySetParams));
        dispatch(fetchSuccess());
    } catch (error) {
        if (error.response?.status === 401) {
            dispatch(refreshToken(bindAccount, value));
        } else if (error.response?.status === 400) {
            dispatch(fetchError("Неверно указан аккаунт"));
        } else {
            dispatch(fetchError(internalServerError));
        }
    }
};

export const activeManagerStatusAccount = (values) => async (dispatch) => {
    dispatch(fetchStart());
    try {
        const removeUndefined = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== undefined));
        const {data} = await httpClient.put(`user/active_account/${removeUndefined.id}/`, removeUndefined);
        const message =
            "is_active" in values
                ? data.is_active
                    ? "Пользователь активирован"
                    : "Пользователь деактивирован"
                : "Пользователь изменен";
        dispatch({
            type: LOAD_MANAGE_ACCOUNT,
            payload: data
        });
        dispatch(showMessage(message));
    } catch (error) {
        if (error.response?.status === 401) {
            dispatch(refreshToken(activeManagerStatusAccount, values));
        } else {
            dispatch(fetchError(internalServerError));
        }
    }
};

export const updateAccount = (values) => async (dispatch) => {
    dispatch(fetchStart());
    try {
        const removeUndefined = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== undefined));
        const {data} = await httpClient.put(`user/update_account/${removeUndefined.id}/`, removeUndefined);
        dispatch({
            type: LOAD_MANAGE_ACCOUNT,
            payload: data
        });
        dispatch(showMessage("Пользователь изменен"));
    } catch (error) {
        if (error.response?.status === 401) {
            dispatch(refreshToken(updateAccount, values));
        } else {
            dispatch(fetchError(internalServerError));
        }
    }
};

export const deleteAccount = (value) => async dispatch => {
    try {
        const {dataAccount, querySetParams} = value;
        dispatch(fetchStart());
        await httpClient.delete(`user/delete_account/${dataAccount.id}/`);
        dispatch(getManageAccount(querySetParams));
        dispatch(showMessage('Пользователь удален'));
    } catch (error) {
        if (error.response?.status === 401) {
            dispatch(refreshToken(deleteAccount, value));
        } else {
            dispatch(fetchError(internalServerError));
        }
    }
};

export const isLoadingUser = (status) => {
    return {
        type: IS_LOADING_USER,
        payload: status
    }
};
