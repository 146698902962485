import {Button, Col, Form, Input, InputNumber, Row, Select} from "antd";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {addPacket} from "../../appRedux/actions/Packet";

const {Option} = Select;

const AddPacket = () => {

    const {authUser} = useSelector(state => state.auth);
    const {manageAccountForFilter} = useSelector(state => state.customer)
    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const onFinish = values => {
        dispatch(addPacket(JSON.parse(JSON.stringify(values))))
        form.resetFields()
    };

    const onChange = (value) => {
        if (value === null)
            form.setFieldsValue({"account": undefined})
    }

    return (
        <Form
            form={form}
            initialValues={{remember: true}}
            name="basic"
            onFinish={onFinish}
        >
            <Row justify="space-between">
                <Col lg={6} md={6} sm={12} xs={24}>
                    <div className="gx-form-row0">
                        <Form.Item
                            name="name"
                            rules={[{required: true, message: 'Please input name packet!'}]}
                        >
                            <Input placeholder="Name packet"/>
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24}>
                    <div className="gx-form-row0">
                        <Form.Item
                            name="price"
                            rules={[{required: true, message: 'Please input price packet!'}]}
                        >
                            <InputNumber style={{marginRight: 0, width: '100%'}}
                                         placeholder="Price"
                                         min={authUser.credit_price}
                                         max={10}
                                         step={0.1}
                                         onChange={onChange}
                            />
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24}>
                    <div className="gx-form-row0">
                        <Form.Item
                            name="account"
                            rules={[{required: true, message: 'Please select type data!'}]}
                        >
                            <Select placeholder="Please select account"
                                    style={{width: '100%'}}
                                    onChange={onChange}
                            >
                                {
                                    authUser.is_superuser && <Option value={'true'}>{'Дефолтный пакет'}</Option>
                                }
                                {
                                    manageAccountForFilter
                                        .filter(obj => obj.is_active && authUser.is_superuser ? obj.manager === 0 : true)
                                        .map(
                                            x => <Option key={x.id} value={x.id}>{x.email}</Option>
                                        )
                                }
                            </Select>
                        </Form.Item>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24}>
                    <div className="gx-form-row0">
                        <Form.Item>
                            <Button
                                style={{width: '100%'}}
                                className="gx-mb-0"
                                type="primary"
                                htmlType="submit"
                            >
                                Создать
                            </Button>
                        </Form.Item>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default AddPacket
